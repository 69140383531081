<template>
  <b-card no-body>
    <b-tabs card>
      <b-tab v-if="can('Show details broker')" active title="Détails">
        <details-cabinet :correspondant-tracfin="correspondantTracfin"
                         :current-user="currentUser"
                         :declarant-tracfin="declarantTracfin"
                         :dpo="dpo"
                         :interlocuteurs="interlocuteurs"
                         @record-courtier-juridique-returned="RecordCourtierJuridiqueReturned"
                         @change-collaborateur-notion-returned="ChangeCollaborateurNotionReturned"
        />
      </b-tab>
      <b-tab v-if="can('List of insured codes')" title="Codes assureurs">
        <codes-assureurs-cabinet/>
      </b-tab>
      <!-- <b-tab v-if="can('List compliances')" title="Conformité">
        <conformite-cabinet
            :courtier-id="currentUser.courtier_user[0].courtier_id"
            :is-refresh-data-conformite="isRefreshDataConformite"
            :user-id="currentUser.courtier_user[0].user_id"
            @is-data-refreshed="isRefreshDataConformite = false"
        />
      </b-tab> -->
      <b-tab v-if="isPrincipal() || can('List notes')" title="Bordereaux">
        <bordereaux-documents :courtier-id="currentUser.courtier_user[0].courtier_id"
                       :user-id="currentUser.courtier_user[0].user_id"
        />
      </b-tab>
      <b-tab v-if="can('List other documents')" title="Autres documents">
        <autres-documents :courtier-id="currentUser.courtier_user[0].courtier_id"
                          :user-id="currentUser.courtier_user[0].user_id"
        />
      </b-tab>
      <!-- <b-tab title="Signature protocole" v-if="can('Signature protocole')" v-show="false">
        <signature-protocole />
      </b-tab> -->
      <b-tab v-if="can('List notes')" title="Notes">
        <notes-cabinet :courtier-id="currentUser.courtier_user[0].courtier_id"
                       :user-id="currentUser.courtier_user[0].user_id"
        />
      </b-tab>

    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BTab, BTabs } from 'bootstrap-vue'
import DetailsCabinet from './detail-cabinet/DetailsCabinet.vue'
import CodesAssureursCabinet from './codes-assureurs-cabinet/CodesAssureursCabinet.vue'
import ConformiteCabinet from './conformite-cabinet/ConformiteCabinet.vue'
import AutresDocuments from './autres-documents/AutresDocuments.vue'
import SignatureProtocole from './signature-protocole/SignatureProtocole.vue'
import NotesCabinet from './notes-cabinet/NotesCabinet.vue'
import BordereauxDocuments from './bordereaux/BordereauxDocuments.vue'

export default {
  components: {
    // BTV
    BTabs,
    BTab,
    BCard,
    // UI
    DetailsCabinet,
    CodesAssureursCabinet,
    ConformiteCabinet,
    AutresDocuments,
    SignatureProtocole,
    NotesCabinet,
    BordereauxDocuments
  },
  props: {
    currentUser: {
      type: Object,
      default: null,
      required: true
    },
    interlocuteurs: {
      type: Object,
      default: null,
      required: true,
    },
    correspondantTracfin: {
      type: Object,
      default: null,
      required: true,
    },
    declarantTracfin: {
      type: Object,
      default: null,
      required: true,
    },
    dpo: {
      type: Object,
      default: null,
      required: true,
    }
  },
  data() {
    return {
      isRefreshDataConformite: false
    }
  },
  methods: {
    RecordCourtierJuridiqueReturned(data, message) {
      this.$emit('record-courtier-juridique-returned', data, message)
      this.isRefreshDataConformite = true

    },
    ChangeCollaborateurNotionReturned() {
      this.$emit('change-collaborateur-notion-returned')
    }
  }
}
</script>
