<template>
  <div>
    <b-sidebar id="sidebar-juridique" ref="sidebarJuridique" backdrop bg-variant="white" no-header
               right shadow sidebar-class="sidebar-lg"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">VOTRE STRUCTURE</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
        </div>
        <validation-observer ref="cabinetJuridiqueComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- clients list -->
            <h4>INFORMATION</h4> 
            <b-form-group label="Forme juridique *" label-for="forme_juridique">
              <validation-provider #default="{ errors }" name="forme juridique" rules="required">
                <!-- <b-form-input id="forme_juridique" v-model="composeData.courtier.forme_juridique"
                              :class="errors.length > 0 ? 'is-invalid' : ''"
                              placeholder="Forme juridique"
                /> -->
                <b-form-select v-model="composeData.courtier.forme_juridique" :class="errors.length > 0 ? 'is-invalid' : ''">
                  <b-form-select-option :value="null">Sélectionner une forme juridique</b-form-select-option>
                  <b-form-select-option v-for="form_jur in Form_Juridique" :value="form_jur.value" :key="form_jur.value">{{ form_jur.text }}</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Créée le *" label-for="cree_le">
              <validation-provider #default="{ errors }" name="créée le" rules="required">
                <flat-pickr id="date-creation" v-model="composeData.courtier.date_creation"
                            :class="errors.length > 0 ? 'is-invalid' : ''"
                            :config="configDate" class="form-control" placeholder="Créée le"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Numéro d'orias *" label-for="n_orias">
              <b-form-input id="n_orias" v-model="courtier.numero_orias" disabled placeholder="Numéro d'orias"/>
            </b-form-group>

            <b-form-group label="Numéro siren *" label-for="n_siren">
              <validation-provider #default="{ errors }" name="numéro siren" rules="required|integer">
              <b-form-input id="n_siren" v-model="composeData.courtier.siren" v-mask="'#########'"
                            :class="errors.length > 0 ? 'is-invalid' : ''" placeholder="Numéro siren"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                  errors[0]
                }}
              </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Numéro siret *" label-for="n_siret">
              <validation-provider #default="{ errors }" name="numéro siret" rules="required|integer">
                <b-form-input id="n_siret" v-model="composeData.courtier.siret" v-mask="'##############'"
                              :class="errors.length > 0 ? 'is-invalid' : ''" placeholder="Numéro siret"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Lieu RCS *" label-for="n_rcs">
              <validation-provider #default="{ errors }" name="lieu RCS" rules="required">
                <b-form-input id="n_rcs" v-model="composeData.courtier.num_rcs"
                              :class="errors.length > 0 ? 'is-invalid' : ''" placeholder="Lieu RCS"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Capital social *" label-for="capital_social">
              <validation-provider #default="{ errors }" name="capital social" rules="required|integer">
                <b-form-input id="capital_social" v-model="composeData.courtier.capital_social"
                              :class="errors.length > 0 ? 'is-invalid' : ''"
                              placeholder="Forme juridique"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Effectif *" label-for="effectif">
              <validation-provider #default="{ errors }" name="effectif" rules="required">
                <b-form-select id="effectif" v-model="composeData.courtier.effectif"
                               :class="errors.length > 0 ? 'is-invalid' : ''"
                               :options="Nombre_employes"
                               text-field="text" value-field="value"
                >
                  <template #first>
                    <b-form-select-option :value="null">Selectionner le nombre d'employés</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Êtes-vous membre d'une association d'autorégulation ?">
              <validation-provider v-slot="{ errors }" name="association autorégulation" rules="required"
                                   vid="is_anassociation_autoregulation"
              >
                <b-form-radio-group v-model="composeData.isAnAssociationAutoregulation"
                                    :options="options.IsHaveAssociationAutoregulation" class="demo-inline-spacing"
                                    name="radio-inline"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <div v-if="composeData.isAnAssociationAutoregulation">
              <validation-provider #default="{ errors }" name="association autorégulation"
                                   rules="required_if:is_anassociation_autoregulation,true"
                                   vid="id_anassociation_autoregulation"
              >
                <b-form-group label="Association autorégulation" label-for="AssociationAutoregulation">
                  <b-form-select id="AssociationAutoregulation" v-model="composeData.IdAssociationAutoregulation"
                                 :options="listAssociationAutoregulations"
                                 :state="errors.length > 0 ? false : null" text-field="libelle" value-field="id"
                  >
                    <template #first>
                      <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
              <div v-if="composeData.IdAssociationAutoregulation">

                <!-- <b-form-group v-if="composeData.IdAssociationAutoregulation === 1" label="Numéro adhérent endya"
                              label-for="numero_adherent_endya"
                >
                   <b-form-input disabled id="numero_adherent_endya" v-model="composeData.courtier.siren"
                                  placeholder="Numéro adhérent endya"
                    />
                </b-form-group> -->

                <!-- Document association autorégulation -->

                <b-form-group label="Voulez-vous ajouter le document pour l'association d'autorégulation?">
                  <validation-provider v-slot="{ errors }" name="document association autorégulation" rules="required"
                                       vid="is_document_anassociation_autoregulation"
                  >
                    <b-form-radio-group v-model="composeData.isAnDocumentAssociationAutoregulation"
                                        :options="options.IsAddDocumentAssociationAutoregulation"
                                        class="demo-inline-spacing"
                                        name="radio-inline-document"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                        errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <div v-if="composeData.isAnDocumentAssociationAutoregulation">
                  <b-form-group label="Date début*" label-for="date-debut">
                    <validation-provider #default="{ errors }" name="date début"
                                         rules="required_if:is_document_anassociation_autoregulation"
                    >
                      <flat-pickr id="date-debut-coformite" v-model="composeData.document.dateDebut"
                                  :class="errors.length > 0 ? 'is-invalid' : ''"
                                  :config="configDate" class="form-control" placeholder="date début"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                          errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Date fin*" label-for="date-fin">
                    <validation-provider #default="{ errors }" name="date fin"
                                         rules="required_if:is_document_anassociation_autoregulation"
                    >
                      <flat-pickr id="date-fin-coformite" v-model="composeData.document.dateFin"
                                  :class="errors.length > 0 ? 'is-invalid' : ''"
                                  :config="configDate" class="form-control" placeholder="date fin"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                          errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="mt-2" label-for="h-document">
                    <validation-provider #default="{ errors }" name="document"
                                         rules="required_if:is_document_anassociation_autoregulation"
                    >
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-button v-b-tooltip.hover="'Parcourir le fichier'"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'"
                                    class="btn-icon" @click="openToSelectFile"
                          >
                            <b-form-file id="filecoformiteJuridique" v-model="composeData.document.file" class="d-none"
                                         type="file"
                            />
                            <feather-icon icon="UploadCloudIcon"/>
                          </b-button>
                        </b-input-group-prepend>
                        <b-form-input :state="errors.length > 0 ? false : null"
                                      :value="composeData.document.file ? composeData.document.file.name : ''"
                                      disabled
                                      placeholder="Choisissez un document"
                        />
                      </b-input-group>

                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                          errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </div>

              </div>
            </div>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" variant="primary"
                        @click="recordCourtier"
              > Enregistrer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BOverlay,
  BSidebar,
  VBToggle,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { integer, required, requiredIf } from '@validations'
import enums from '../../../../shared/constants/prospect'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormSelect,
    BFormRadioGroup,
    BFormSelectOption,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormFile,
    BAvatar,

    flatPickr,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    courtier: {
      type: Object,
      default: '',
      required: true
    }
  },
  data() {
    return {
      required,
      requiredIf,
      integer,
      Form_Juridique: enums.FORMEJURIDIQUE,
      Nombre_employes: enums.NOMBRE_EMPLOYES,
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            const ele = document.activeElement
            let val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          uppercase: true
        },
        IsHaveAssociationAutoregulation: [
          {
            text: 'Oui',
            value: true
          },
          {
            text: 'Non',
            value: false
          }
        ],
        IsAddDocumentAssociationAutoregulation: [
          {
            text: 'Oui',
            value: true
          },
          {
            text: 'Non',
            value: false
          }
        ]
      },
      villes: [],
      isUpdateAction: false,
      villeOptions: [],
      listAssociationAutoregulations: [],
      composeData: {
        idCourtier: '',
        isAnAssociationAutoregulation: false,
        IdAssociationAutoregulation: '',
        isAnDocumentAssociationAutoregulation: false,
        NRAssociationAutoregulation: '',
        //courtier properties
        courtier: {
          idPersonneMorale: '',
          date_creation: '',
          forme_juridique: '',
          siret: '',
          siren: '',
          num_rcs: '',
          capital_social: '',
          effectif: ''
        },
        document: {
          dateDebut: '',
          dateFin: '',
          file: null,
          id: '',
          documentId: '',
          idDocumentConformite: 10,
          idType: 39,
          isSendEmail: 0,
        }
      }
    }
  },
  watch: {
    'composeData.isAnAssociationAutoregulation': {
      handler(val) {
        if (!val) {
          this.composeData.IdAssociationAutoregulation = ''
          this.composeData.NRAssociationAutoregulation = ''
        } else {
          this.composeData.IdAssociationAutoregulation = this.courtier.association_autoregulation_id
          this.composeData.NRAssociationAutoregulation = this.courtier.numero_adherent_endya
          // this.getFirstDocumentCourtierConformiteNotHistorisedByIdType(this.courtier.id, 10)
        }
      }
    },
    courtier: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.composeData.idCourtier = val.id
          this.composeData.isAnAssociationAutoregulation = !!val.association_autoregulation_id
          this.composeData.IdAssociationAutoregulation = val.association_autoregulation_id
          this.composeData.NRAssociationAutoregulation = val.numero_adherent_endya
          this.composeData.isAnDocumentAssociationAutoregulation = false
        }
      }
    }
  },
  created() {
    //courtier data
    this.composeData.courtier.idPersonneMorale = this.courtier.personne_morale.id
    this.composeData.courtier.date_creation = this.changeFormatDateShort(this.courtier.personne_morale.date_creation)
    this.composeData.courtier.forme_juridique = this.courtier.personne_morale.forme_juridique
    this.composeData.courtier.siret = this.courtier.personne_morale.siret
    this.composeData.courtier.siren = this.courtier.personne_morale.siren
    this.composeData.courtier.num_rcs = this.courtier.personne_morale.num_rcs
    this.composeData.courtier.capital_social = this.courtier.personne_morale.capital_social
    this.composeData.courtier.effectif = this.courtier.personne_morale.effectif
    this.composeData.isAnDocumentAssociationAutoregulation = false
    this.fetchDataAssociationAutoregulation()
  },

  methods: {
    // Methode additional
    hideSideBare() {
      this.$refs.sidebarJuridique.hide()
    },
    openToSelectFile: () => {
      document.getElementById('filecoformiteJuridique')
          .click()
    },
    clearData() {
      this.composeData.idCourtier = ''
      this.composeData.isAnAssociationAutoregulation = false
      this.composeData.isAnDocumentAssociationAutoregulation = false
      this.composeData.IdAssociationAutoregulation = ''
      this.composeData.NRAssociationAutoregulation = ''

      this.composeData.document.dateDebut = ''
      this.composeData.document.dateFin = ''
      this.composeData.document.file = null
      this.composeData.document.id = ''
      this.composeData.document.documentId = ''
      this.composeData.document.idDocumentConformite = 10
      this.composeData.document.idType = 39
      this.composeData.document.isSendEmail = 0

      this.$refs.cabinetJuridiqueComposeRules.reset()
    },
    // fetch data
    fetchDataAssociationAutoregulation() {
      this.$http
          .get('/association_autoregulation/fetchDataAssociationAutoregulation')
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.listAssociationAutoregulations.push({
                  id: el.id,
                  libelle: el.libelle,
                })
              })
            }
          })
          .catch(err => {
            this.listAssociationAutoregulations = []
            console.error(err)
          })
    },
    getFirstDocumentCourtierConformiteNotHistorisedByIdType(courtierId, IdAssociationAutoregulation) {
      this.$http
          .get(`/document_conformite/getFirstDocumentCourtierConformiteNotHistorisedByIdType/${courtierId}/${IdAssociationAutoregulation}`)
          .then(res => {
            if (res.data.success) {
              this.composeData.document.id = res.data.data.id
              this.composeData.document.documentId = res.data.data.document_id
              this.composeData.document.dateFin = res.data.data.date_fin
              this.composeData.document.dateDebut = res.data.data.date_debut
              this.composeData.document.file = null
              this.composeData.document.idDocumentConformite = 10
              this.composeData.document.idType = 39
              this.composeData.document.isSendEmail = 0
            }
          })
          .catch(err => {
            this.composeData.document.dateDebut = ''
            this.composeData.document.dateFin = ''
            this.composeData.document.file = null
            this.composeData.document.id = ''
            this.composeData.document.documentId = ''
            this.composeData.document.idDocumentConformite = 10
            this.composeData.document.idType = 39
            this.composeData.document.isSendEmail = 0
            console.error(err)
          })
    },

    // record Data courtier
    recordCourtier() {
      this.$refs.cabinetJuridiqueComposeRules.validate()
          .then(success => {
            if (success) {
              const formData = new FormData()
              // Append the rest of your form data
              formData.append('idCourtier', this.checkIsNull(this.composeData.idCourtier))
              formData.append('isAnAssociationAutoregulation', this.composeData.isAnAssociationAutoregulation)
              formData.append('isAnDocumentAssociationAutoregulation', this.composeData.isAnDocumentAssociationAutoregulation ? 1:0)
              formData.append('IdAssociationAutoregulation', this.checkIsNull(this.composeData.IdAssociationAutoregulation))
              formData.append('NRAssociationAutoregulation', this.checkIsNull(this.composeData.courtier.siren))
              // Courtier
              formData.append('courtier[idPersonneMorale]', this.checkIsNull(this.composeData.courtier.idPersonneMorale))
              formData.append('courtier[date_creation]', this.checkIsNull(this.composeData.courtier.date_creation))
              formData.append('courtier[forme_juridique]', this.checkIsNull(this.composeData.courtier.forme_juridique))
              formData.append('courtier[siret]', this.checkIsNull(this.composeData.courtier.siret))
              formData.append('courtier[siren]', this.checkIsNull(this.composeData.courtier.siren))
              formData.append('courtier[num_rcs]', this.checkIsNull(this.composeData.courtier.num_rcs))
              formData.append('courtier[capital_social]', this.checkIsNull(this.composeData.courtier.capital_social))
              formData.append('courtier[effectif]', this.checkIsNull(this.composeData.courtier.effectif))
              // document
              formData.append('document[dateDebut]', this.checkIsNull(this.composeData.document.dateDebut))
              formData.append('document[dateFin]', this.checkIsNull(this.composeData.document.dateFin))
              formData.append('document[file]', this.checkIsNull(this.composeData.document.file))
              formData.append('document[id]', this.checkIsNull(this.composeData.document.id))
              formData.append('document[documentId]', this.checkIsNull(this.composeData.document.documentId))
              formData.append('document[idDocumentConformite]', this.checkIsNull(this.composeData.document.idDocumentConformite))
              formData.append('document[idType]', this.checkIsNull(this.composeData.document.idType))
              formData.append('document[isSendEmail]', this.checkIsNull(this.composeData.document.isSendEmail))

              // send request
              const config = { headers: { 'Content-Type': 'multipart/form-data' } }
              this.$http
                  .post('/courtier/updataDataJuridique', formData, config)
                  .then(res => {
                    if (res.data.success) {

                      this.$emit('record-courtier-juridique-returned', res.data.data, 'Les modifications ont été faites avec succès.')

                      this.clearData()
                      this.hideSideBare()
                    }
                  })
                  .catch(err => {
                    console.error(err)
                  })
            }
          })
    },
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
