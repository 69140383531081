<template>
  <div>
    <b-card header="Coordonnée de la société" border-variant="primary" bg-variant="transparent" header-bg-variant="primary" header-text-variant="white" header-class="p-1 font-weight-bolder" body-class="p-2">
      <b-card-text>
        <b-row>
          <b-col sm="12" md="4" class="mb-1">
            <b-form-group>
              <label for="raison-sociale">Raison sociale</label>
              <b-form-input id="raison-sociale" disabled placeholder="Raison sociale" />
            </b-form-group>
          </b-col>

          <b-col sm="12" md="4" class="mb-1">
            <b-form-group>
              <label for="denomination-commerciale">Dénomination commerciale</label>
              <b-form-input id="denomination-commerciale" disabled placeholder="Dénomination commerciale" />
            </b-form-group>
          </b-col>

          <b-col sm="12" md="4" class="mb-1">
            <b-form-group>
              <label for="statut-societe">Statut de la société <feather-icon icon="AlertCircleIcon" /></label>
              <b-form-input id="disabledInput" disabled placeholder="Statut de la société" />
            </b-form-group>
          </b-col>

          <b-col sm="12" md="4">
            <b-form-group>
              <label for="capital-societe">Capital de la société <feather-icon icon="AlertCircleIcon" /></label>
              <b-form-input id="capital-societe" disabled placeholder="Capital de la société" />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="4">
            <b-form-group>
              <label for="n-immatriculation">N° Immatriculation <feather-icon icon="AlertCircleIcon" /></label>
              <b-form-input id="n-immatriculation" disabled placeholder="N° Immatriculation" />
            </b-form-group>
          </b-col>

          <b-col sm="12" md="4">
            <b-form-group>
              <label for="telephone">Téléphone</label>
              <b-form-input id="telephone" disabled placeholder="Téléphone" />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="12" class="mt-2 text-right">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"> Signer votre protocole électroniquement </b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCardText, BCard, BRow, BCol, BFormInput, BFormGroup, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // BTV
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton
  },
  directives: {
    Ripple
  }
}
</script>
