<template>
  <div>
    <div class="custom-search d-flex justify-content-end" v-if="isGestionCabinet()">
      <b-form-group>
        <div class="d-flex align-items-center" >
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"  v-b-toggle.sidebar-code-assureurs-cabinet>
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Ajouter un code assureur externe</span>
          </b-button>
        </div>
      </b-form-group>
      <actions-code-assureurs-cabinet  id="code-assureurs-cabinet" @code-assureur-externe-updated ="codeAssureurExUpdated" />
      <update-code-assureurs-cabinet  :code-data-to-edit="codeDataToEdit" ref="sideBarUpdateCodeAssureurs"  id="update-code-assureurs-cabinet" @code-assureur-externe-updated ="codeAssureurExUpdated" />
    </div>
    <div v-else>
      <add-code-users ref="sideBarAddCodeUsers" :interlocuteurs="interlocuteurs" :id-assureur-selected="idAssureurSelected" :courtier-id="courtierId ? courtierId : currentUser.courtier_user[0].courtier_id"  id="add-code-users" />
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <b-form-select id="Filtre" v-model="searchTerm" :options="optionsSearchTerm">
            <template #first>
              <b-form-select-option :value="null" selected>-- Tous --</b-form-select-option>
            </template>
          </b-form-select>
          <!-- <b-form-select v-model="searchTerm" :options="optionsSearchTerm" class="d-inline-block" /> -->
          <!-- <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" /> -->
        </div>
      </b-form-group>
    </div>

    <!-- table -->

    <b-overlay :show="showLoadingo" no-wrap />
    <vue-good-table
        v-if="can('List of insured codes')"
      :columns="columnsCodesAssureurs"
      :rows="rowsCodesAssureurs"
      :rtl="direction"
      style-class="vgt-table condensed"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: params => ``
      }"
    >
      <template slot="table-row" slot-scope="props" >
        <!-- Column: Action -->
        <span v-if="props.column.field == 'produits' " >
          <span v-if="props.row.isCodeInterne">
              <span v-if="props.row.produits !== 'Non affecté' " v-html="props.row.produits.map(item => item.produit_nom).join(', <br>')"></span>
              <span v-else>{{ props.row.produits }}</span>
          </span>
          <span v-else>
              <span  v-html="props.row.produits"></span>
          </span>
        </span>

        <span v-else-if="props.column.field == 'email'">
          <a v-if="props.row.email"
            :style="isBoiteSync ? { 'text-decoration': 'underline'} : { 'text-decoration': 'initial', 'cursor': 'default'}"
            @click="showEmail(props.row.email)">
            {{ props.row.email }} 
          </a> <br>
         <span>{{ props.row.telAssureur }}</span> 
        </span>
        <span v-else-if="props.column.field == 'sourceData'">
            <b-badge :variant="sourceVariant(props.row.sourceData)" v-if="props.row.sourceData == 'Interne'" style="font-size: 0.9375rem "> {{ capitalizeFirstLetter(props.row.sourceData) }} </b-badge>
            <span v-else>{{ props.row.sourceData }} </span>
        </span>
        <span  v-else-if="props.column.field ==='urlAssureur'">
        <b-link v-if="props.row.urlAssureur" target="_blank" :href="validateURL(props.row.urlAssureur)">{{ props.row.urlAssureur }}</b-link>
        </span>
        <span v-else-if="props.column.field === 'actions'" >
          <span v-if="isGestionCabinet() && !props.row.isCodeInterne">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
              </template>
              <template v-if="!props.row.isCodeInterne">
                <b-dropdown-item @click="editCodeAssureurExterne(props.row.id)">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Éditer</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteCodeAssureur(props.row.id, props.row.vgt_id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Supprimer</span>
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </span>
          <span v-if="!isGestionCabinet()">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
              </template>
              <b-dropdown-item @click="editCodeAssureur(props.row.id)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Éditer</span>
              </b-dropdown-item>
              <template v-if="props.row.isCodeInterne && props.row.assureur == 'ECA ASSURANCES'">
                  <b-dropdown-item @click="addCodeUsers(props.row.assureurId)">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span>Ajout de code utilisateur</span>
                  </b-dropdown-item>
              </template>
              <template v-if="props.row.isCodeInterne && props.row.assureur == 'ECA ASSURANCES'">
                  <b-dropdown-item @click="Visualiser(props.row.assureurId)">
                    <feather-icon icon="SearchIcon" class="mr-50" />
                    <span>visualiser codes utilisateurs</span>
                  </b-dropdown-item>
              </template>
              <b-dropdown-item @click="deleteCodeAssureur(props.row.id, props.row.vgt_id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
        <b-row class="mt-2 align-items-center">
          <b-col>
            <!-- <b-input-group>
              <b-form-select v-model="selected" :options="options" />
              <b-input-group-append>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
                  <span>Valider </span>
                  <feather-icon icon="ArrowRightIcon" class="mr-50" />
                </b-button>
              </b-input-group-append>
            </b-input-group> -->
          </b-col>
          <b-col>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div class="text-center align-middle" slot="emptystate">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>
    <b-modal id="modal-display-sous-code-utilisateur" ref="modal-display-sous-code-utilisateur" :hide-footer="hideFooter" centered size="lg">
      <membres-cabinet-sous-code :id-assureur-selected="idAssureurSelected"  :courtier-id="courtierId ? courtierId : currentUser.courtier_user[0].courtier_id" />
    </b-modal>

  </div>
</template>

<script>
import { BPagination, BFormGroup, BFormSelect, BFormSelectOption, BRow, BCol, BOverlay,BLink, BDropdown, BDropdownItem,BButton,VBToggle,BBadge} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import ActionsCodeAssureursCabinet from './ActionsCodeAssureursCabinet.vue'
import UpdateCodeAssureursCabinet from './UpdateCodeAssureursCabinet.vue'
import AddCodeUsers from './AddCodeUsers.vue'
import MembresCabinetSousCode from './MembresCabinetSousCode.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BRow,
    BCol,
    BOverlay,
    BLink,
    BDropdown,
    BDropdownItem,
    BButton,
    VBToggle,
    ActionsCodeAssureursCabinet,
    BBadge,
    UpdateCodeAssureursCabinet,
    AddCodeUsers,
    MembresCabinetSousCode
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      codeExterneUpdated :false,
      codeDataToEdit :null,
      showLoadingo: false,
      isBoiteSync: false,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      pageLength: 10,
      dir: false,
      options: [
        { value: null, text: 'Action de masse' },
        { value: 'autre', text: ' --- ' }
      ],
      selected: null,
      hideFooter : false,
      columnsCodesAssureurs: [
        // { label: 'Cabinet', field: 'courtier', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Assureur', field: 'assureur', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6'  },
        // { label: 'Interlocuteur', field: 'interlocuteur', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Code', field: 'code', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Produit(s)', field: 'produits', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Statut', field: 'statut', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' ,},
        { label: 'Contact', field: 'email', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Site web', field: 'urlAssureur', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Interne/Externe', field: 'sourceData', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: `text-center m-0 p-1 align-middle h6`,hidden: this.isGestionCabinet() ? false :true},
        { field: 'actions', label: 'Actions', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6', hidden: (this.courtierId ||( this.isGestionCabinet())) ? false : true }
        // { label: 'Segmentation', field: 'segmentation', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        // { label: 'Inspecteur', field: 'inspecteur', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' }
      ],
      optionsSearchTerm: [
        { value: 'Ouvert', text: 'Codes ouverts' },
        { value: 'Fermer', text: 'Codes fermés' },
      ],
      rowsCodesAssureurs: [],
      rowsCodesAssureursKeeped: [],
      searchTerm: null,
      assureursOptions: [{
          value: null,
          text: '-- Choisissez --'
        }],
      interlocuteurs: [],
      idAssureurSelected: 0,
    }
  },
  props: {
    codeUpdated: {
      type: Boolean,
      default: false,
      required: false
    },
    courtierId: {
      type: Number,
      default: null,
      required: false
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sourceVariant() {
    const statusColor = {
        /* eslint-disable key-spacing */
        // EXTERNE: 'light-primary',
        Interne: 'light-primary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted(){
    this.hideFooter = true
  },
  watch: {
    searchTerm: {
      handler() {
        this.filterDataByStatut()
      }
    },
    codeExterneUpdated :{
      handler(val){
        if(val)
        this.fetchCodeAssureur()
      }
    },
    codeUpdated: {
      handler(val) {
        if(val) {
          this.fetchCodeAssureur()
        }
      }
    },
  },
  created() {
    this.checkSyncBoiteEmail()
    this.fetchCodeAssureur()
    this.fetchInterlocuteurs()
  },
  methods: {
    Visualiser(idAssureurSelected){
      this.idAssureurSelected = idAssureurSelected
      this.$refs['modal-display-sous-code-utilisateur'].show()
    },
     codeAssureurExUpdated() {
      this.codeExterneUpdated = true
      setTimeout(() => {
        this.codeExterneUpdated = false
      }, 1000);
    },
    isGestionCabinet(){
     return this.$route.name === 'gestion-cabinet';
    },
    editCodeAssureurExterne(idCodeAssureur) {
      this.showLoadingo = true
      const courtierId = this.courtierId ? this.courtierId : this.currentUser.courtier_user[0].courtier_id
      this.$http.get(`/code_assureur/getCodeExterneAssureurDetailById/${idCodeAssureur}/${courtierId}`)
        .then(res => {
          if(res.data.data)
          console.log(res.data.data)
           this.codeDataToEdit = res.data.data;
          this.showLoadingo = false
          this.$root.$emit('bv::toggle::collapse', 'sidebar-update-code-assureurs-cabinet');
        })
        .catch(err => {
          this.showLoadingo = false
          console.log(err)
        })
    },
    editCodeAssureur(idCodeAssureur) {
      this.showLoadingo = true
      const courtierId = this.courtierId ? this.courtierId : this.currentUser.courtier_user[0].courtier_id
      this.$http.get(`/code_assureur/getCodeAssureurDetailById/${idCodeAssureur}/${courtierId}`)
        .then(res => {
          if(res.data.data)
            this.$emit('update-code-assureur',res.data.data)
            this.showLoadingo = false
        })
        .catch(err => {
          this.showLoadingo = false
          console.log(err)
        })
    },
    addCodeUsers(idCodeAssureur) {
      this.idAssureurSelected = idCodeAssureur
      this.$root.$emit('bv::toggle::collapse', 'sidebar-add-code-users');
    },
    deleteCodeAssureur(id, vgtId) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer ce code assureur ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(confirmed => {
        if (confirmed.value) {
          this.showLoadingo = true
          const courtierId = this.courtierId ? this.courtierId : this.currentUser.courtier_user[0].courtier_id
          if(!this.isGestionCabinet()){
            this.$http
              .delete(`/code_assureur/deleteCodeAssureur/${id}/${courtierId}`)
              .then(res => {
                if (res.data.success) {
                  const indexDocument = this.rowsCodesAssureurs.findIndex(item => item.id === id)
                  this.rowsCodesAssureurs.splice(indexDocument, 1)
                  this.messageToast(res.data.message, 'Succès', 'success')
                } else {
                  this.messageToast(res.data.message, 'Erreur', 'error')
                }
                this.showLoadingo = false
              })
              .catch(error => {
                this.messageToast(error.response.data.errors[0], 'Erreur', 'error')
                this.showLoadingo = false
              })
            
            }else{
              this.$http
              .delete(`/code_assureur/deleteCodeAssureurExterne/${id}/${courtierId}`)
              .then(res => {
                if (res.data.success) {
                  const indexDocument = this.rowsCodesAssureurs.findIndex(item => item.id === id)
                  this.rowsCodesAssureurs.splice(indexDocument, 1)
                  this.messageToast(res.data.message, 'Succès', 'success')
                } else {
                  this.messageToast(res.data.message, 'Erreur', 'error')
                }
                this.showLoadingo = false
              })
              .catch(error => {
                this.messageToast(error.response.data.errors[0], 'Erreur', 'error')
                this.showLoadingo = false
              })
            }
        }
      })
    },
    fetchCodeAssureur() {
      this.showLoadingo = true
      const courtierId = this.courtierId ? this.courtierId : this.currentUser.courtier_user[0].courtier_id
      this.$http
        .post('/code_assureur/fetchCodesAssureurs', { courtierId })
        .then(res => {
          if (res.data.success) {

            this.rowsCodesAssureurs = res.data.data

            this.rowsCodesAssureursKeeped = res.data.data
            this.showLoadingo = false
          } else {
            this.rowsCodesAssureurs = []
            this.rowsCodesAssureursKeeped = []
            this.showLoadingo = false
          }
        })
        .catch(err => {
          this.rowsCodesAssureurs = []
          this.rowsCodesAssureursKeeped = []
          this.showLoadingo = false
          console.error(err)
        })
    },
    fetchInterlocuteurs() {
      const courtierId = this.courtierId ? this.courtierId : this.currentUser.courtier_user[0].courtier_id
      this.$http
      .post('/collaborateur/fetchInterlocuteurs', { courtierId })
      .then(res => {
        if (res.data.success) {
          this.interlocuteurs = res.data.data
        }
      })
      .catch(err => {
        console.error(err)
      })
    },
    filterDataByStatut() {
      console.log(this.rowsCodesAssureurs)
      this.rowsCodesAssureurs = this.searchTerm === null ? this.rowsCodesAssureursKeeped : this.rowsCodesAssureursKeeped.filter(item => item.statut === this.searchTerm)
    },
    showEmail (email) {
      if(this.isBoiteSync) {
          this.$router.push({
            name: 'messageries',
            params: {
              operation: 'send',
              email
            }
          })
      }
    },
    checkSyncBoiteEmail() {
      this.isBoitEmailSync().then(response => {
        if(response)
          this.isBoiteSync = true
        else this.isBoiteSync = false
      })
    },
  }
}
</script>
<style scoped>
.violet-background{
  background-color: turquoise;
}
</style>
