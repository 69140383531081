<template>
  <div>
    <b-sidebar :id="'sidebar-document-' + id" bg-variant="white" sidebar-class="sidebar-lg" ref="sideBarDocument" shadow backdrop no-header right>
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Nouveau document</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="documentComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- clients list -->

            <b-form-group label="Famille de document" label-for="h-famille">
              <validation-provider #default="{ errors }" name="famille de document" rules="required">
                <b-form-select id="h-famille-autre-document" v-model="famille" :options="optionsFamille" :state="errors.length > 0 ? false : null" @change="changeListoptionType()">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Type de document" label-for="h-type">
              <validation-provider #default="{ errors }" name="type de document" rules="required">
                <b-form-select id="h-type-autre-document" v-model="composeData.idType" :options="optionsType" :state="errors.length > 0 ? false : null">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Nom du document*" label-for="h-nom-document" v-if="isAutre">
              <validation-provider #default="{ errors }" name="nom du document" rules="required">
                <b-form-input id="h-nom-document-autre-document" v-model="composeData.nom" placeholder="Nom du document" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- <b-form-group label="Description" label-for="h-Description">
              <b-form-textarea id="h-Description-autre-document" placeholder="Description" rows="3" v-model="composeData.description" />
            </b-form-group> -->
          <validation-provider #default="{ errors }" name="collaborateur" v-if="!isInterfaceCollaborateur">
            <b-form-group :state="errors.length > 0 ? false : null" label="Collaborateur" label-for="collaborateur">
              <v-select v-model="composeData.collaborateur" :close-on-select="true"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="collaborateurOptions" class="flex-grow-1"
                input-id="collaborateur" label="nom" placeholder="collaborateur">
                <template #option="{ avatar, nom }">
                  <b-avatar :src="avatar" size="sm" />
                  <span class="ml-50"> {{ nom }}</span>
                </template>

                <template #selected-option="{ avatar, nom }">
                  <b-avatar :src="avatar" class="border border-white" size="sm" />
                  <span class="ml-50"> {{ nom }}</span>
                </template>
                <div slot="no-options">Aucune collaborateur disponible.</div>
              </v-select>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                              errors[0]
                            }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

            <b-form-group class="mt-2" label-for="h-document">
              <validation-provider #default="{ errors }" name="document" rules="required">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Parcourir le fichier'" @click="openToSelectFile">
                      <b-form-file type="file" id="fileautredocument" class="d-none" v-model="composeData.file" />
                      <feather-icon icon="UploadCloudIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input disabled placeholder="Choisissez un document" :value="composeData.file ? composeData.file.name : ''" :state="errors.length > 0 ? false : null" />
                </b-input-group>

                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="recordDocument"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BInputGroup, BInputGroupPrepend, BButton, BSidebar, VBToggle, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BFormSelect, BFormSelectOption, BFormTextarea, BFormFile, VBTooltip, BAvatar, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/named
import documentService from '@/shared/services/documentService'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormFile,
    vSelect,
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    id: {
      type: String,
      default: 'cabinet',
      required: true
    },
    courtierId: {
      type: Number,
      default: null,
      required: true
    },
    userId: {
      type: Number,
      default: null,
      required: false
    },
    isInterfaceCollaborateur: {
      type: Boolean,
      default: false,
      required: false
    },
    collaborateurOptions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => [],
      required: false
    },
  },
  data() {
    return {
      required,
      famille: null,
      optionsType: [],
      typeSelected: null,
      optionsFamille: [],
      isAutre:false,
      composeData: {
        id: null,
        nom: null,
        description: '',
        idFamille: null,
        idType: null,
        file: null,
        courtier_id: this.courtierId,
        user_id: null,
        collaborateur: null,
      }
    }
  },
  created() {
    this.fetchDocumentNatureWithTypesNonConformite()
  },
  watch: {
    userId: {
      handler(val) {
        this.composeData.user_id = val
      }
    },
  },
   mounted() {
    this.$watch(
      (vm) => [vm.composeData.idFamille,vm.composeData.idType],
      (val) => {
          if( val[1] == 52 || val[1] == 53 ||  val[1] == 45 ||val[1]==25 ){
            this.isAutre =true 
          }else{
              this.isAutre =false
          }
      },
      
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    // Methode additional
    hideSideBare() {
      this.$refs.sideBarDocument.hide()
    },
    openToSelectFile: () => {
      document.getElementById('fileautredocument').click()
    },
    changeListoptionType() {
      if (this.famille) {
        this.composeData.idFamille = this.famille.id
        this.composeData.idType = null
        this.optionsType = []
        this.famille.typeDocument.forEach(el => {
          this.optionsType.push({
            value: el.id,
            text: el.type
          })
        })
      } else {
        this.composeData.idFamille = null
        this.composeData.idType = null
        this.optionsType = []
      }
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    clearData() {
      this.composeData.id = null
      this.composeData.nom = null
      this.composeData.description = ''
      this.composeData.idFamille = null
      this.composeData.idType = null
      this.composeData.file = null
      this.composeData.courtier_id = this.courtierId
      this.composeData.user_id = this.userId
      this.composeData.collaborateur = null
      this.typeSelected = null
      this.famille = null
      this.$refs.documentComposeRules.reset()
    },
    // record Data document courtier
    recordDocument() {
      this.$refs.documentComposeRules.validate().then(success => {
        if (success) {
          // Set formData
          const formData = new FormData()
          // Append the rest of your form data
          formData.append('id', this.composeData.id)
          formData.append('nom', this.composeData.nom && this.composeData.nom != null && this.composeData.nom != '' && this.composeData.nom != 'null' ? this.composeData.nom : this.composeData.file.name.substring(0, this.composeData.file.name.lastIndexOf(".")))
          formData.append('description', this.composeData.description)
          formData.append('idType', this.composeData.idType)
          formData.append('file', this.composeData.file)
          formData.append('courtier_id', this.courtierId)
          let collaborateurId = this.userId
          if(!this.isInterfaceCollaborateur)
            collaborateurId = this.composeData.collaborateur ? this.composeData.collaborateur.id : null

          formData.append('user_id', collaborateurId)

          // send request
          const config = { headers: { 'Content-Type': 'multipart/form-data' } }
          this.$http
            .post('/document/createNewDocumentCourtier', formData, config)
            .then(res => {
              if (res.data.success) {
                this.$emit('record-document-courtier-returned', res.data.data, res.data.message)
                this.clearData()
                this.hideSideBare()
              } else {
                this.messageToast('Erreur lors de creation de nouveau document.', 'Erreur', "danger")
              }
            })
            .catch(err => {
              this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
              console.error(err)
            })
        }
      })
    },
    fetchDocumentNatureWithTypesNonConformite() {
        this.optionsFamille = []
        this.$http
          .post('/document_nature/fetch_document_nature_with_types_non_conformite')
          .then(response => {
            response.data.forEach(el => {
              this.optionsFamille.push({
                value: {
                  id: el.id,
                  typeDocument: el.documentype
                },
                text: el.libelle
              })
            })
          })
          .catch(errors => {
            console.log(errors)
            this.optionsFamille = []
          });
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
