<template>
  <div>
    <b-sidebar id="sidebar-cabinet" bg-variant="white" sidebar-class="sidebar-lg" ref="sideBarCabinet" shadow backdrop no-header right>
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Modifier courtier</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="cabinetComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- clients list -->
            <h4>INFORMATION</h4>

            <b-form-group label="Dénomination commerciale" label-for="denomination_commercial">
              <b-form-input id="denomination_commercial" v-model="composeData.personneMorale.denomination_commercial" placeholder="Dénomination commerciale" />
            </b-form-group>

            <b-form-group label="Raison sociale" label-for="raison_sociale">
              <b-form-input id="raison_sociale" v-model="composeData.personneMorale.raison_sociale" placeholder="Raison sociale" />
            </b-form-group>

            <h4>COORDONNÉES</h4>
            <b-form-group label="N° et libellé de la voie" label-for="nEtLibelleVoie">
              <b-form-input id="nEtLibelleVoie" v-model="composeData.moyenContact.libelle" placeholder="N° et libellé de la voie" />
            </b-form-group>

            <b-form-group label="Complément d'adresse (Société, Bâtiment, N°App, Lieu dit, etc.)" label-for="complementAdresse">
              <b-form-input id="complementAdresse" v-model="composeData.moyenContact.complement_adresse" placeholder="Complément d'adresse (Société, Bâtiment, N°App, Lieu dit, etc.)" />
            </b-form-group>

            <b-form-group label="Lieu-dit ou BP" label-for="lieuDitOuBp">
              <b-form-input id="lieuDitOuBp" v-model="composeData.moyenContact.lieu_dit_ou_bp" placeholder="Lieu-dit ou BP" />
            </b-form-group>

            <b-form-group label="Code Postal" label-for="codePostal">
              <b-form-input id="codePostal" v-model="composeData.moyenContact.code_postal" placeholder="Code Postal" />
            </b-form-group>

            <b-form-group label="Ville" label-for="ville">
              <b-form-select id="ville" v-model="composeData.moyenContact.ville_id" :options="villes" value-field="id" text-field="ville">
                <template #first>
                  <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>

            <h4>CONTACT</h4>
            <b-form-group label="Email" label-for="email">
              <validation-provider #default="{ errors }" name="Email" rules="email">
                <b-form-input id="email" v-model="composeData.moyenContact.email" placeholder="Email" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

          <b-form-group label="Téléphone portable" label-for="phone">
                <div class="d-flex bd-highlight">
                    <div class="w-25 bd-highlight">
                          <v-select v-model="composeData.moyenContact.indicatif_tel" :close-on-select="true" :clearable="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif" 
                            input-id="indicatif_phone" label="indicatif" class="indicatif-chooser w-100"
                        >
                            <template #option="{ indicatif,code }">
                              <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                            </template>
                            <template #selected-option="{ indicatif }">
                              <span> {{ indicatif }}</span>
                            </template>
                            <div slot="no-options">Aucune indicatif disponible.</div>
                        </v-select>
                    </div>
                <div class="w-75 bd-highlight">
                  <validation-provider #default="{ errors }" name="numéro de téléphone" rules="required" :rules="{ regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }">
                    <b-form-input
                                    id="phone"
                                    style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"                          
                                    trim
                                    placeholder="ex: 06 00 00 00 00"
                                    v-model="composeData.moyenContact.tel"
                                    :state="errors.length > 0 ? false : null"
                                    v-mask="'## ## ## ## ##'"
                                    
                                  />
                    <small class="text-danger" :style="errors.length > 0 ? 'margin-left:-88px' : '' " >{{ errors[0] }}</small>
                  </validation-provider>
               </div>
            </div>
            </b-form-group>

            <b-form-group label="Numéro de téléphone fixe" label-for="phone_fixe">
                <div class="d-flex bd-highlight">
                    <div class="w-25 bd-highlight">
                          <v-select v-model="composeData.moyenContact.indicatif_tel_sec" :close-on-select="true" :clearable="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif" 
                            input-id="indicatif_phone_fixe" label="indicatif" class="indicatif-chooser w-100"
                        >
                            <template #option="{ indicatif,code }">
                              <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                            </template>
                            <template #selected-option="{ indicatif }">
                              <span> {{ indicatif }}</span>
                            </template>
                            <div slot="no-options">Aucune indicatif disponible.</div>
                        </v-select>
                    </div>
                <div class="w-75 bd-highlight">
              <validation-provider #default="{ errors }" name="Numéro de téléphone fixe" :rules="{ regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }">
                  <b-form-input
                                    id="phone_fixe"
                                    style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"                          
                                    trim
                                    placeholder="ex: 06 00 00 00 00"
                                    v-model="composeData.moyenContact.tel_secondaire"
                                    :state="errors.length > 0 ? false : null"
                                    v-mask="'## ## ## ## ##'"
                                    
                                  />
                    <small class="text-danger" :style="errors.length > 0 ? 'margin-left:-88px' : '' " >{{ errors[0] }}</small>
              </validation-provider>
              </div>
            </div>
            </b-form-group>

            <b-form-group label="site web" label-for="site web">
              <validation-provider #default="{ errors }" name="site web" rules="url">
                <b-form-input id="site web" v-model="composeData.moyenContact.site_web" :state="errors.length > 0 ? false : null" placeholder="www.bubble-in.com" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ site web est invalide</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Bio" label-for="Bio">
                <b-form-input id="Bio" v-model="composeData.personneMorale.bio"  placeholder="Bio" />
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="recordCourtier"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BButton, BSidebar, VBToggle, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BFormSelect, BFormSelectOption } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, url } from '@validations'
import Cleave from 'vue-cleave-component'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    Cleave,
    BFormSelect,
    BFormSelectOption,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    personneMorale: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      required,
      url,
      pays:[],
      indicatif:[],
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          uppercase: true
        }
      },
      villes: [],
      isUpdateAction: false,
      villeOptions: [],
      composeData: {
        idMoyenContact: null,
        idpersonneMorale: null,
        personneMorale: {
          denomination_commercial: null,
          raison_sociale: null,
          bio: null
        },
        moyenContact: {
          adresse: null,
          complement_adresse: null,
          lieu_dit_ou_bp: null,
          code_postal: null,
          ville_id: null,
          tel: null,
          indicatif_tel:null,
          tel_secondaire: null,
          indicatif_tel_sec:null,
          site_web: null,
          email: null
        }
      }
    }
  },
  watch: {
    'composeData.moyenContact.code_postal': {
      immediate: true,
      handler(val) {
        this.GetVille(val)
      }
    },
    personneMorale: {
      deep: true,
      immediate: true,
      handler(val) {
        if(val) {
         this.composeData.idpersonneMorale = val.id
        this.composeData.personneMorale.denomination_commercial = val.denomination_commercial
        this.composeData.personneMorale.raison_sociale = val.raison_sociale
        this.composeData.personneMorale.bio = val.bio
        this.composeData.idMoyenContact = val.moyen_contact.id
        this.composeData.moyenContact.libelle = val.moyen_contact.libelle
        this.composeData.moyenContact.lieu_dit_ou_bp = val.moyen_contact.lieu_dit_ou_bp
        this.composeData.moyenContact.code_postal = val.moyen_contact.code_postal
        this.composeData.moyenContact.ville_id = val.moyen_contact.ville_id
        this.composeData.moyenContact.complement_adresse = val.moyen_contact.complement_adresse
        this.composeData.moyenContact.tel = val.moyen_contact.tel
        this.composeData.moyenContact.indicatif_tel = val.moyen_contact.indicatif_tel
        this.composeData.moyenContact.tel_secondaire = val.moyen_contact.tel_secondaire
        this.composeData.moyenContact.indicatif_tel_sec = val.moyen_contact.indicatif_tel_sec
        this.composeData.moyenContact.site_web = val.moyen_contact.site_web
        this.composeData.moyenContact.email = val.moyen_contact.email
        }
      }
    }
  },
  created(){
      this.$http.get(`tiers/getPays`)
      .then((r) => {
        this.pays=r.data
        let france = r.data.find(item => item.indicatif == "+33")
        let indexFrance = r.data.indexOf(france)
        //r.data.splice(indexFrance, 1, france);
        r.data.sort(function(x,y){ return x == france ? -1 : y == france ? 1 : 0; })
        this.indicatif = r.data
      })
      .catch((e)=>{
        console.log(e);
      })
  },
  methods: {
    // Methode additional
    hideSideBare() {
      this.$refs.sideBarCabinet.hide()
    },
    GetVille(code) {
      if (code && code.length === 5) {
        this.$http
          .get(`/helpers/${code}/villes`)
          .then(res => {
            if (res.data?.length > 0) {
              this.villes = [...res.data]
              this.composeData.moyenContact.ville_id = this.villes[0].id
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.villes = []
      }
    },

    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    // record Data courtier
    recordCourtier() {
      this.$refs.cabinetComposeRules.validate().then(success => {
        if (success) {
          this.$http
            .post('/courtier/updataDataCourtierMoyenContact', this.composeData)
            .then(res => {
              if (res.data.success) {

                const message = 'Les modifications ont été faites avec succès.'
                this.$emit('record-courtier-returned', this.composeData.moyenContact, message)
                this.$refs.cabinetComposeRules.reset()
                this.hideSideBare()
              }
            })
            .catch(err => {
              this.messageToast(err.response.data.errors[0], 'Erreur', 'warning')
            })
        }
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
#sidebar-cabinet input#indicatif.vs__search{
  padding: 0px;
}
#sidebar-cabinet .vs__selected-options{
  height: 31px;
}
#sidebar-cabinet .indicatif-chooser .vs__dropdown-toggle {
  border-radius: 0.357rem 0px 0px 0.357rem;
  padding-bottom: 4.5px;
}
#sidebar-cabinet .v-select.indicatif-chooser.vs--open.vs--single.vs--searchable
{
  min-width: 20%;
  width:20%;
}
#sidebar-cabinet .vs__dropdown-toggle{
  width:111%
}
</style>
