<template>
  <div>
    <!-- Title page -->
    <div class="mx-auto mb-2">
      <b-row class="align-items-center">
        <b-col md="6">
          <span class="h1 text-primary font-weight-bolder text-uppercase ml-1 my-0 mr-1">Gestion cabinet</span>
          <b-badge variant="light-success">{{ getValuePromoByCode($_.get(currentUser.courtier_user[0].courtier.abonnement, 'promotion.code', '')) }}</b-badge>
        </b-col>
      </b-row>
    </div>

    <!-- Card global infos -->
    <b-card>
      <b-card-header class="px-0" v-if="interlocuteurs.interlocuteur">
        <h3 class="text-dark font-weight-bolder">
          <span>
            {{ $_.get(interlocuteurs.interlocuteur, 'courtier.personne_morale.denomination_commercial', null) }} </span>
          <b-button v-if="can('Update broker informations')" v-b-toggle.sidebar-cabinet
            v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" class="btn-icon rounded-circle">
            <feather-icon icon="Edit3Icon" size="16" />
          </b-button>
        </h3>
        <update-cabinet v-if="can('Update broker informations')"
          :personne-morale="$_.get(interlocuteurs.interlocuteur, 'courtier.personne_morale', null)"
          @record-courtier-returned="recordCourtierReturned" />
      </b-card-header>
      <b-card-text>
        <b-row>
          <b-col sm="12" md="9">
            <b-col sm="12" md="12">
              <!-- <b-row>
                <b-col cols="12" class="pb-2"
                  v-if="$_.get(currentUser.courtier_user[0], 'courtier.personne_morale.denomination_commercial', null)">
                  Sous la marque -
                  {{ $_.get(currentUser.courtier_user[0], 'courtier.personne_morale.denomination_commercial', null) }}
                </b-col>
              </b-row> -->
              <b-row class="mt-2"
                v-if="$_.get(interlocuteurs.interlocuteur, 'courtier.personne_morale.raison_sociale', null)">
                <b-col md="2" sm="12" class="mb-2 mb-sm-0">
                  Raison Sociale :
                </b-col>
                <b-col md="10" sm="12">
                  <b-col cols="12">
                    {{ $_.get(interlocuteurs.interlocuteur, 'courtier.personne_morale.raison_sociale', null) }}
                  </b-col>
                </b-col>
              </b-row>
              <b-row class="mt-2" v-if="interlocuteurs.dirigeant">
                <b-col md="2" sm="12" class="mb-2 mb-sm-0">
                  Dirigeant :
                </b-col>
                <b-col md="10" sm="12">
                  <b-col cols="12">
                    {{ $_.get(interlocuteurs.dirigeant.user.personne_physique, 'civilite', null) + ' ' + $_.get(interlocuteurs.dirigeant.user.personne_physique, 'nom', null) + ' ' + $_.get(interlocuteurs.dirigeant.user.personne_physique, 'prenom', null)  }}
                  </b-col>
                  <b-col cols="12">
                     {{ $_.get(interlocuteurs.dirigeant.user.personne_physique.moyen_contact, 'indicatif_tel', null)&&  $_.get(interlocuteurs.dirigeant.user.personne_physique.moyen_contact, 'tel', null) ? '('+$_.get(interlocuteurs.dirigeant.user.personne_physique.moyen_contact, 'indicatif_tel', null)+') '+$_.get(interlocuteurs.dirigeant.user.personne_physique.moyen_contact, 'tel', null).substring(1) :$_.get(interlocuteurs.dirigeant.user.personne_physique.moyen_contact, 'tel', null) }}
                  </b-col>
                  <b-col cols="12">
                    {{ $_.get(interlocuteurs.dirigeant.user.personne_physique.moyen_contact, 'email', null)  }}
                  </b-col>
                </b-col>
              </b-row>
              <b-row class="mt-2" v-if="interlocuteurs.interlocuteur">
                <b-col md="2" sm="12" class="mb-2 mb-sm-0">
                  Coordonnées :
                </b-col>
                <b-col md="10" sm="12">
                  <!-- <b-col cols="12">
                    {{ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.adresse', null) ? $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.adresse', null) : '' }}
                  </b-col> -->
                  <b-col cols="12">
                    {{ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.libelle', null) ? $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.libelle', null) : '' }}
                    {{ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.complement_adresse', null) ? $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.complement_adresse', null) : '' }}
                  </b-col>
                  <b-col cols="12"
                    v-if="$_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.lieu_dit_ou_bp', null)">
                    {{ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.lieu_dit_ou_bp', null) }}
                  </b-col>
                  <b-col sm="6" md="12">
                    {{ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.ville.code_postal', null) + ' - ' + $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.ville.commune', null) }}
                  </b-col>
                  <b-col cols="12"
                    v-if="$_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.tel', null)">
                    {{ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.tel') && $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.indicatif_tel') ? '('+$_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.indicatif_tel')+ ') '+ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.tel').substring(1) :$_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.tel') }}
                  </b-col>
                  <b-col cols="12"
                    v-if="$_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.email', null)">
                    {{ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.email', null) }}
                  </b-col>
                  <b-col cols="12"
                    v-if="$_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.site_web', null)">
                    {{ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.site_web', null) }}
                  </b-col>
                </b-col>
              </b-row>
            </b-col>
            <b-col sm="12" md="12">
              <b-row>
                <!-- <b-col sm="6" md="12" class="pb-1" v-if="$_.get(currentUser.courtier_user[0], 'courtier.source', null)"> Raison Sociale : {{ $_.get(currentUser.courtier_user[0], 'courtier.personne_morale.raison_sociale', null) }} </b-col>
                <b-col sm="6" md="12" class="pb-1"> Code Postal - Ville : {{ $_.get(currentUser.courtier_user[0], 'courtier.personne_morale.moyen_contact.adresse', null) + ' - ' + $_.get(currentUser.courtier_user[0], 'courtier.personne_morale.moyen_contact.adresse', null) }} </b-col>
                <b-col cols="12" class="pb-2" v-if="$_.get(currentUser.courtier_user[0], 'courtier.personne_morale.moyen_contact.site_web', null)">  Site web : {{ $_.get(currentUser.courtier_user[0], 'courtier.personne_morale.moyen_contact.site_web', null) }} </b-col>
                <b-col cols="12" class="pb-2" v-if="$_.get(currentUser.courtier_user[0], 'courtier.sous_statut', null)">  Sous Statut : {{ $_.get(currentUser.courtier_user[0], 'courtier.sous_statut', null) }} </b-col> -->
              </b-row>
            </b-col>
          </b-col>
          <b-col sm="12" md="3">
            <b-overlay :show="showLoadingForLogo" no-wrap />
            <b-media class="mb-2">
              <template #aside>
                <b-row>
                  <b-col cols="12" class="text-center" style="border:1px solid #ebe9f1;">
                    <b-img :src="logoData.imgBase64" fluid
                      :alt="'Logo ' + $_.get(currentUser.courtier_user[0], 'courtier.personne_morale.raison_sociale', null)"
                      width="auto" height="auto" style="padding: 8px;max-width:200px;" />
                  </b-col>
                  <b-col cols="12" v-if="can('Update logo')" class="p-0">
                    <b-button block class="rounded-0" variant="primary" @click="$refs.refInputEl.click()">
                      <input ref="refInputEl" type="file" class="d-none" @change="onFileChange" />
                      <feather-icon icon="UploadCloudIcon" /> Mettre à jour
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-media>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <!-- Sections cabinet management -->
    <sections-gestion-cabinet :current-user="currentUser"
                              :interlocuteurs="interlocuteurs"
                              :correspondant-tracfin="correspondantTracfin"
                              :declarant-tracfin="declarantTracfin"
                              :dpo="dpo"
                              @record-courtier-juridique-returned="RecordCourtierJuridiqueReturned"
                              @change-collaborateur-notion-returned="getInterlocuteurs" />
  </div>
</template>

<script>
import { BBadge, VBToggle, BRow, BCol, BButton, BCard, BCardText, BCardHeader, BMedia, BImg, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import SectionsGestionCabinet from './SectionsGestionCabinet.vue'
import UpdateCabinet from './UpdateCabinet.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    BMedia,
    BImg,
    BOverlay,
    BBadge,

    // UI
    SectionsGestionCabinet,
    UpdateCabinet
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem('userData')),
      logoData: {
        imgBase64: null
      },
      interlocuteurs: {},
      correspondantTracfin: {},
      declarantTracfin: {},
      dpo: {},
      inputImageRenderer: null,
      showLoadingForLogo: false
    }
  },
  created() {
    this.getInterlocuteurs();
    this.getLogoBase64()
  },
  methods: {
    getValuePromoByCode(code){
      switch (code) {
        case 'BUBBLE2M':
          return '2 mois gratuits'
        case 'BUBBLE2022':
          return '6 mois gratuits'
        default:
          return ''
      }
    },
    getLogoBase64() {
      this.showLoadingForLogo = true
      this.$http
        .get(`courtier/getLogoCourtierBase64/${this.currentUser.courtier_user[0].courtier.id}`)
        .then(res => {
          if (res.data.success) {
            if (res.data.data) {
              this.logoData.imgBase64 = res.data.data
              this.showLoadingForLogo = false
            } else {
              this.logoData.imgBase64 = null
              this.showLoadingForLogo = false
            }
          }
        })
        .catch(err => {
          this.showLoadingForLogo = false
          console.error(err)
        })
    },
    getInterlocuteurs() {
      this.$http
        .get(`courtier/getInterlocuteursCourtier/${this.currentUser.courtier_user[0].courtier.id}`)
        .then(res => {
          if (res.data.success) {
            if (res.data.data) {
              this.interlocuteurs = res.data.data
              this.correspondantTracfin = res.data.data.correspondantTracfin
              this.declarantTracfin = res.data.data.declarantTracfin
              this.dpo = res.data.data.dpo
            }
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    onFileChange() {
      if (this.$refs.refInputEl.files[0]) {
        this.$swal({
          title: 'Etes-vous sûr de vouloir modifier le logo?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(confirmed => {
          if (confirmed.value) {
            this.showLoadingForLogo = true
            // eslint-disable-next-line prefer-destructuring
            this.inputImageRenderer = this.$refs.refInputEl.files[0]
            if (this.inputImageRenderer) {
              const config = { headers: { 'Content-Type': 'multipart/form-data' } }
              const formData = new FormData()
              formData.append('logo', this.inputImageRenderer)
              formData.append('courtier_id', this.currentUser.courtier_user[0].courtier_id)

              this.$http
                .post('/courtier/updateLogoCourtier', formData, config)
                .then(res => {
                  if (res.data.success) {
                    this.logoData.imgBase64 = res.data.data
                    this.showLoadingForLogo = false
                  } else {
                    this.logoData.imgBase64 = null
                    this.showLoadingForLogo = false
                  }
                })
                .catch(error => {
                  if (error.response) {
                    this.showLoadingForLogo = false
                    this.messageToast(error.response.data.errors[0], 'Erreur', 'error')
                  } else {
                    this.showLoadingForLogo = false
                    this.messageToast('La ressource demandée ne prend pas en charge le type de support fourni.', 'Erreur', 'error')
                  }
                })
            } else {
              this.showLoadingForLogo = false
              this.messageToast('Logo manquant, veuillez de re-sélectionner le logo que vous souhaitez télécharger.', 'Erreur', 'error')
            }
          }
        })
      }
    },
    RecordCourtierJuridiqueReturned(data, message) {
      this.currentUser.courtier_user[0].courtier = data
      localStorage.setItem('userData', JSON.stringify(this.currentUser))
      this.getInterlocuteurs(this.currentUser.courtier_user[0].courtier_id)
      this.messageToast(message, 'Succès', 'success')
    },
    recordCourtierReturned(moyenContact, message) {
      this.currentUser.courtier_user[0].courtier.personne_morale.moyen_contact.tel_secondaire = moyenContact.tel_secondaire
      this.currentUser.courtier_user[0].courtier.personne_morale.moyen_contact.tel = moyenContact.tel
      this.currentUser.courtier_user[0].courtier.personne_morale.moyen_contact.site_web = moyenContact.site_web
      localStorage.setItem('userData', JSON.stringify(this.currentUser))
      this.getInterlocuteurs(this.currentUser.courtier_user[0].courtier_id)
      this.messageToast(message, 'Succès', 'success')
    }
  },
  setup() {
    return {
      avatarText
    }
  }
}
</script>

<style></style>
