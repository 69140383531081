<template>
  <div>
    <b-sidebar :id="'sidebar-' + id" bg-variant="white" sidebar-class="sidebar-lg" ref="sideBarActionsCodeAssureurs" shadow backdrop no-header right>
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">ajout code d'assureur externe</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="codeAssureurComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- clients list -->      
            <b-form-group label="Assureur*" label-for="assureur">
              <validation-provider #default="{ errors }" name="assureur" rules="required">
                <b-form-input id="num_contrat" v-model="composeData.assureur"  :state="errors.length > 0 ? false : null" placeholder="Assureur" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Code*" label-for="code">
              <validation-provider #default="{ errors }" name="code" rules="required">
                <b-form-input id="num_contrat" v-model="composeData.code"  :state="errors.length > 0 ? false : null" placeholder="Code" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Produit" label-for="produit">
              <validation-provider #default="{ errors }" name="produit" >
                <b-form-input id="num_contrat" v-model="composeData.produit"  :state="errors.length > 0 ? false : null" placeholder="produit" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Statut"   label-for="statut" >
                <validation-provider  #default="{ errors }"   name="Statut"    >
             <b-form-select
                  v-model="composeData.statut"
                  :options="status"
                  id="statut"
                  :state="errors.length > 0 ? false : null"
                  />
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
            <b-form-group label="Contact" label-for="contact">
              <validation-provider #default="{ errors }" name="contact">
                <b-form-input id="num_contrat" v-model="composeData.contact"  :state="errors.length > 0 ? false : null" placeholder="Contact" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Site web" label-for="contact">
              <validation-provider #default="{ errors }" name="site_web" >
                <b-form-input id="num_contrat" v-model="composeData.site_web"  :state="errors.length > 0 ? false : null" placeholder="Site web" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="recordCodeAssureur"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BInputGroup, BInputGroupPrepend, BButton, BSidebar, VBToggle, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BFormSelect, BFormSelectOption, BFormTextarea, BFormFile, VBTooltip, BAvatar, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/named
import documentService from '@/shared/services/documentService'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { mapGetters,mapActions } from "vuex";

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormFile,
    vSelect,
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    VBToggle
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
     id: {
      type: String,
      default: '-code-assureurs-cabinet',
      required: true
    },
   rowsCodesAssureurs: {
      type: Object,
      default: null,
      required: false
    },
  codeDataToEdit: {
    type: Object,
    default: null,
  },
  },
    computed: {
    ...mapGetters(['getAssureur']),
       assureur() {
      return this.getAssureur; // Access getAssureur as a property, not a function
    },
  },
  data() {
    return {
      required,
      isUpdateAction: false, 
      assureursLoaded: false,
      composeData: {
      assureur: this.codeDataToEdit ? this.codeDataToEdit.assureur : null,
      code: this.codeDataToEdit ? this.codeDataToEdit.code_direct : null,
      produit: this.codeDataToEdit ? this.codeDataToEdit.produit : '',
      statut: this.codeDataToEdit ? this.codeDataToEdit.statut : null,
      site_web: this.codeDataToEdit ? this.codeDataToEdit.site_web : null,
      contact: this.codeDataToEdit ? this.codeDataToEdit.contact : null,
    },
     status:[
          { value: null, text: 'Choisissez' },
          { value: 'Ouvert', text: 'Ouvert' },
          { value: 'Fermer', text: 'Fermer' },
     ]
    }
  },
  watch:{
    codeDataToEdit: {
      handler(val) {
        this.isUpdateAction =true
      this.mapDataCodeAssureurExterne(val)
      },
      immediate: true
    },
  },
  mounted(){
      
  },
  methods: {
      mapDataCodeAssureurExterne(data) {
      this.composeData.assureur = data.assureur
      this.composeData.code = data.code_direct
      this.composeData.produit = data.produit
      this.composeData.statut = data.statut
      this.composeData.site_web = data.site_web
      this.composeData.contact = data.contact
  },
    // Methode additional
    hideSideBare() {
      this.$refs.sideBarActionsCodeAssureurs.hide()
    },
  
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    clearData() {
    this.composeData.assureur= null,
    this.composeData.code= null,
    this.composeData.produit= null,
    this.composeData.statut= null,
    this.composeData.site_web= null,
    this.composeData.contact= null,
    this.$refs.codeAssureurComposeRules.reset()
    },

    // record Data Code Assureur Externe
    recordCodeAssureur() {
      this.$refs.codeAssureurComposeRules.validate().then(success => {
        if (success) {
          // Set formData
          const formData = new FormData()
          // Append the rest of your form data
          formData.append('assureur', this.composeData.assureur)
          formData.append('code', this.composeData.code)
          formData.append('produit', this.composeData.produit)
          formData.append('statut', this.composeData.statut)
          formData.append('site_web', this.composeData.site_web)
          formData.append('contact', this.composeData.contact)
          // send request
          this.$http
            .post('/code_assureur/recordCodeAssureurExterne', formData)
            .then(res => {
              if (res.data.success) {
                this.clearData()
                this.hideSideBare()
                this.$emit('code-assureur-externe-updated')
                this.messageToast(res.data.message, 'Succès', 'success')
              } else {
                this.messageToast('Erreur lors de creation de nouveau document.', 'Erreur', "danger")
              }
            })
            .catch(err => {
              this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
              console.error(err)
            })
        }
      })
    },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
