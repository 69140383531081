<template>
  <div>
    <b-sidebar id="sidebar-note" bg-variant="white" sidebar-class="sidebar-lg" ref="sideBarNote" shadow backdrop no-header right no-close-on-backdrop>
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <!-- <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Modifier courtier</h5> -->
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Nouvelle note</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="noteComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
           
  <!-- Note Quill -->
  <b-form-group label="Note" label-for="note">
              <validation-provider #default="{ errors }" name="Note" rules="required">
                <quill-editor id="h-Note" v-model="composeData.note" :options="snowOption" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="recordNote"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BButton, BSidebar, VBToggle, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BFormSelect, BFormSelectOption, BFormTextarea } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    Cleave,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    courtierId: {
      type: Number,
      default: null,
      required: true
    },
    userId: {
      type: Number,
      default: null,
      required: true
    },
   
  },
  data() {
    return {
      required,
      composeData: {
        id: null,
        note: null,
        userId: this.userId,
        courtierId: this.courtierId,
                gestionnaire_id:null,

      },
       snowOption: {
        theme: 'snow',
        placeholder: 'Écrivez une note...'
      },
    }
  },
 
  methods: {
    // Methode additional
    hideSideBare() {
      this.$refs.sideBarNote.hide()
    },

    // record Data courtier
    recordNote() {
      this.$refs.noteComposeRules.validate().then(success => {
        if (success) {
          this.$http
            .post('/note/actionNewNoteCourtier', this.composeData)
            .then(res => {
              if (res.data.success) {
                this.$emit('record-note-returned', res.data.data, res.data.message)
                this.composeData.note = null
                this.composeData.id = null
                this.$refs.noteComposeRules.reset()
                this.hideSideBare()
              }
            })
            .catch(err => {
              this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
              console.error(err)
            })
        }
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

</style>
