<template>
  <div>
    <b-sidebar id="sidebar-document-conformite" ref="sideBarDocumentConformite" backdrop
               bg-variant="white" no-header right shadow sidebar-class="sidebar-lg"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Nouveau document</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
        </div>
        <validation-observer ref="documentComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- Document -->

            <b-overlay :show="showLoading" no-wrap />
            <b-form-group label="Famille de document*" label-for="h-nature">
              <validation-provider #default="{ errors }" name="famille de document" rules="required">
                <b-form-select id="h-nature-coformite" v-model="natureSelected" :state="errors.length > 0 ? false : null"
                               vid="isCollaborateurAssujetti-coformite"
                >
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                  <b-form-select-option v-for="(tc, index) in typeList" :key="index" :value="tc">
                    {{ tc.natureLibelle }}
                  </b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Type de document*" label-for="h-type">
              <validation-provider #default="{ errors }" name="type de document" rules="required">
                <b-form-select id="h-type-coformite" v-model="typeSelected"
                               :state="errors.length > 0 ? false : null"
                >
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                  <b-form-select-option v-for="(tc, index) in natureList" :key="index" :value="tc">
                    {{ tc.typeLibelle }}
                  </b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Date début*" label-for="date-debut">
              <validation-provider #default="{ errors }" name="date début" rules="required">
                <flat-pickr id="date-debut-coformite" v-model="composeData.dateDebut"
                            :class="errors.length > 0 ? 'is-invalid' : ''"
                            :config="configDate" class="form-control" placeholder="date début"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Date fin*" label-for="date-fin">
              <validation-provider #default="{ errors }" name="date fin" rules="required">
                <flat-pickr id="date-fin-coformite" v-model="composeData.dateFin"
                            :class="errors.length > 0 ? 'is-invalid' : ''"
                            :config="configDate" class="form-control" placeholder="date fin"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="hasFormation"
              label="Objet de la formation"
              label-for="objet"
            >
                <b-form-input
                  id="objet"
                  trim
                  placeholder="Objet de la formation"
                  v-model="composeData.objet"
                />
            </b-form-group>

            <b-form-group
              v-if="hasFormation"
              label="Nombre de minutes*"
              label-for="nbr_heure"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Nombre de minutes"
                  rules="required"
                >
                <b-form-input
                  id="nbr_heure"
                  type="number"
                  placeholder="Nombre de minutes"
                  v-model="composeData.nb_heures"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <validation-provider v-if="typeSelected && typeSelected.isCollaborateurAssujetti" #default="{ errors }"
                                 :rules="typeSelected ? (typeSelected.isCollaborateurAssujetti ? 'required' : '') : ''"
                                 name="collaborateur"
            >
              <b-form-group :state="errors.length > 0 ? false : null" label="Collaborateur" label-for="collaborateur">
                <v-select v-model="composeData.collaborateur" :close-on-select="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="collaborateurOptions"
                          class="flex-grow-1"
                          input-id="collaborateur" label="nom" placeholder="collaborateur"
                >
                  <template #option="{ avatar, nom }">
                    <b-avatar :src="avatar" size="sm"/>
                    <span class="ml-50"> {{ nom }}</span>
                  </template>

                  <template #selected-option="{ avatar, nom }">
                    <b-avatar :src="avatar" class="border border-white" size="sm"/>
                    <span class="ml-50"> {{ nom }}</span>
                  </template>
                  <div slot="no-options">Aucune collaborateur disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!--            <b-form-group label="Nom du document" label-for="h-nom-document">-->
            <!--                <b-form-input id="h-nom-document-coformite" v-model="composeData.nom" placeholder="Nom du document" />-->
            <!--            </b-form-group>-->

            <!--            <b-form-group label="Description" label-for="h-Description">-->
            <!--              <b-form-textarea id="h-Description-coformite" placeholder="Description" rows="3" v-model="composeData.description" />-->
            <!--            </b-form-group>-->

            <b-form-group class="mt-2" label-for="h-document">
              <validation-provider #default="{ errors }" name="document" rules="required">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button v-b-tooltip.hover="'Parcourir le fichier'" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'"
                              class="btn-icon" @click="openToSelectFile"
                    >
                      <b-form-file id="filecoformite" v-model="composeData.file" class="d-none" type="file"/>
                      <feather-icon icon="UploadCloudIcon"/>
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input :state="errors.length > 0 ? false : null"
                                :value="composeData.file ? composeData.file.name : ''"
                                disabled
                                placeholder="Choisissez un document"
                  />
                </b-input-group>

                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" variant="primary"
                        @click="recordDocumentCourtierCompliance"
              > Enregistrer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BAvatar,
  BOverlay,
  BButton,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BSidebar,
  VBToggle,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, requiredIf } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
// eslint-disable-next-line import/named
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BOverlay,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormFile,
    BAvatar,

    flatPickr,
    vSelect,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    documentConformiteDemande: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true
    },
    collaborateurOptions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true
    },
    courtierId: {
      type: Number,
      default: null,
      required: true
    },
    userId: {
      type: Number,
      default: null,
      required: true
    }
  },
  data() {
    return {
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            const ele = document.activeElement
            let val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }},
      required,
      requiredIf,
      isUserObligatoire: false,
      showLoading: false,
      isSendEmail: false,
      typeSelected: null,
      natureSelected: null,
      typeList: [],
      natureList: [],
      composeData: {
        // document courtier
        idDocumentConformite: null,
        dateDebut: null,
        dateFin: null,
        collaborateur: null,

        // document
        id: null,
        nom: null,
        description: '',
        idType: null,
        file: null,
        courtier_id: this.courtierId,
        objet:null,
        nb_heures:null
      },
      hasFormation:false,
    }
  },
  watch: {
    'composeData.idType':{
      handler(val) {
        if (val == 9) {
          this.hasFormation = true
        }
        else
        {
          this.hasFormation = false
        }
      }
    },
    documentConformiteDemande: {
      deep: true,
      immediate: true,
      handler(val) {
        val.forEach(item => {
          if (this.typeList.filter(e => e.natureId === item.natureId).length <= 0) {
            this.typeList.push({
              natureId: item.natureId,
              natureLibelle: item.natureLibelle,
            })
          }
        })
      },
    },
    natureSelected: {
      deep: true,
      handler(val) {
        if (val) {
          this.natureList = this.documentConformiteDemande.filter(e => e.natureId === val.natureId)
          this.natureList.sort((a, b) =>
           a.typeLibelle.localeCompare(b.typeLibelle));
          this.typeSelected = null
        } else {
          this.natureList = []
          this.typeSelected = null
        }
      }
    },
    typeSelected: {
      handler(val) {
        if (val) {
          this.changeisUserObligatoire(val.isCollaborateurAssujetti)
          this.composeData.idDocumentConformite = val.id
          this.composeData.isSendEmail = !!val.isSendEmail
          this.composeData.idType = val.typeId
          this.isUserObligatoire = !!val.isCollaborateurAssujetti
        }
      }
    }
  },
  methods: {
    // Methode additional
    hideSideBare() {
      this.$refs.sideBarDocumentConformite.hide()
    },
    openToSelectFile: () => {
      document.getElementById('filecoformite').click()
    },
    changeisUserObligatoire(val) {
      this.isUserObligatoire = !!val
    },
    clearData() {
      this.composeData.dateDebut = null
      this.composeData.dateFin = null
      this.composeData.idDocumentConformite = null
      this.composeData.isSendEmail = false
      this.composeData.collaborateur = null
      this.composeData.id = null
      this.composeData.nom = null
      this.composeData.description = ''
      this.composeData.idType = null
      this.composeData.file = null
      this.composeData.courtier_id = this.courtierId
      this.isUserObligatoire = false
      this.typeSelected = null
      this.composeData.objet = null
      this.composeData.nb_heures = null
      this.$refs.documentComposeRules.reset()
    },
    // record Data document courtier
    recordDocumentCourtierCompliance() {
      this.$refs.documentComposeRules.validate()
          .then(success => {
            if (success) {
              // Set formData
              this.showLoading = true
              const formData = new FormData()
              // Append the rest of your form data
              formData.append('id', this.composeData.id)
              formData.append('nom', this.composeData.nom)
              formData.append('description', this.composeData.description)
              formData.append('idType', this.composeData.idType)
              formData.append('file', this.composeData.file)

              formData.append('courtier_id', this.composeData.courtier_id)
              formData.append('id_document_conformite', this.composeData.idDocumentConformite)
              formData.append('isSendEmail', this.composeData.isSendEmail)
              formData.append('date_debut', this.composeData.dateDebut)
              formData.append('date_fin', this.composeData.dateFin ? this.composeData.dateFin : '')
              formData.append('hasFormation', this.hasFormation)
              formData.append('objet', this.composeData.objet ? this.composeData.objet : '')
              formData.append('nb_heures', this.composeData.nb_heures ? this.composeData.nb_heures : '' )

              const collaborateurId = this.composeData.collaborateur ? this.composeData.collaborateur.id : ''
              formData.append('user_id', collaborateurId)
              // send request
              const config = { headers: { 'Content-Type': 'multipart/form-data' } }
              this.$http
                  .post('/document/createNewDocumentCourtierCollaborateur', formData, config)
                  .then(res => {
                    if (res.data.success) {
                      console.log(res.data)
                      this.$emit('record-document-courtier-compliance-returned', res.data.data, res.data.message)
                      this.clearData()
                      this.hideSideBare()
                    } else {
                      this.messageToast('Erreur lors de creation de nouveau document.', 'Erreur', 'error')
                    }
                    this.showLoading = false
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast('Erreur lors de creation de nouveau document.', 'Erreur', 'error')
                    console.error(err)
                  })
            }
          })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
