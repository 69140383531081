<template>
    <div>
      <b-sidebar :id="'sidebar-' + id" bg-variant="white" sidebar-class="sidebar-lg" ref="sideBarAddCodeUsers" shadow backdrop no-header right>
        <template #default="{ hide }">
          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Ajouter sous-code utilisateur</h5>
  
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
          <validation-observer ref="codeUserComposeRules">
            <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
              <!-- clients list -->      
              <b-form-group label="Interlocuteur(s)" label-for="add-interlocuteurs">
                  <validation-provider #default="{ errors }" name="interlocuteurs" rules="required">
                      <v-select v-model="composeData.interlocuteurs" :close-on-select="false"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="interlocuteurs" input-id="add-interlocuteurs"
                          label="interlocuteurs" multiple taggable push-tags key="idCourtierUser" :getOptionLabel="interlocuteur => interlocuteur.nom" >
                          <template #option="{ avatar, nom, prenom }">
                              <b-avatar :src="avatar" size="sm" />
                              <span class="ml-50 align-middle"> {{ nom + ' ' + prenom }}</span>
                          </template>
                          <template #selected-option="{ avatar, nom, prenom }">
                              <b-avatar :src="avatar" class="border border-white" size="sm" />
                              <span class="ml-50 align-middle"> {{ nom + ' ' + prenom }}</span>
                          </template>
                          <div slot="no-options">Aucune interlocuteur disponible.</div>
                      </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Sous-code utilisateur*" label-for="sous_code">
                <validation-provider #default="{ errors }" name="sous code" rules="required">
                  <b-form-input id="sous_code" v-model="composeData.sous_code"  :state="errors.length > 0 ? false : null" placeholder="Sous-code utilisateur" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="addCodeUser"> Enregistrer </b-button>
              </div>
            </b-form>
          </validation-observer>
        </template>
      </b-sidebar>
    </div>
  </template>
  
  <script>
  import { BInputGroup, BInputGroupPrepend, BButton, BSidebar, VBToggle, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BFormSelect, BFormSelectOption, BFormTextarea, BFormFile, VBTooltip, BAvatar, } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import vSelect from 'vue-select'
  import { required } from '@validations'
  import { ref } from '@vue/composition-api'
  // eslint-disable-next-line import/named
  import documentService from '@/shared/services/documentService'
  import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
  import { mapGetters,mapActions } from "vuex";
  
  export default {
    components: {
      BButton,
      BSidebar,
      BFormGroup,
      BFormInput,
      BForm,
      BFormInvalidFeedback,
      BInputGroup,
      BInputGroupPrepend,
      BFormSelect,
      BFormSelectOption,
      BFormTextarea,
      BFormFile,
      vSelect,
      BAvatar,
      // eslint-disable-next-line vue/no-unused-components
      ToastificationContentVue,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      VBToggle
    },
    directives: {
      'b-tooltip': VBTooltip,
      'b-toggle': VBToggle,
      Ripple
    },
    props: {
       id: {
        type: String,
        default: 'add-code-users',
        required: true
      },
     interlocuteurs: {
        type: [Array, Object],
        default: null,
        required: false
      },
      idAssureurSelected: {
        type: Number,
        required: true
      },
      courtierId: {
        type: Number,
        required: true
      }
    },
    computed: {
      ...mapGetters(['getAssureur']),
         assureur() {
        return this.getAssureur; // Access getAssureur as a property, not a function
      },
    },
    data() {
      return {
        isUpdateAction: false, 
        composeData: {
            sous_code: null,
            interlocuteurs: null,
            assureur_id: null,
            courtier_id: null
        }
      }
    },
    mounted(){
        
    },
    methods: {
      // Methode additional
      hideSideBare() {
        this.$refs.sideBarAddCodeUsers.hide()
      },
      
      messageToast(text, title, variant) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title,
            icon: 'BellIcon',
            text,
            variant
          }
        })
      },
      clearData() {
        this.composeData.interlocuteurs= [],
        this.composeData.sous_code= null,
        this.composeData.assureur_id= null,
        this.composeData.courtier_id = null,
        this.$refs.codeUserComposeRules.reset()
      },
  
      // record Data Code Assureur Externe
      addCodeUser() {
        this.$refs.codeUserComposeRules.validate().then(success => {
          if (success) {
            // send request
            this.composeData.assureur_id = this.idAssureurSelected
            this.composeData.courtier_id = this.courtierId
            this.$http
              .post('/code_assureur/addCodeUser', this.composeData)
              .then(res => {
                if (res.data.success) {
                  this.clearData()
                  this.hideSideBare()
                  this.messageToast(res.data.message, 'Succès', 'success')
                } else {
                  this.messageToast('Erreur lors de creation de nouveau sous code.', 'Erreur', "danger")
                }
              })
              .catch(err => {
                this.messageToast(err.response, 'Erreur', "danger")
              })
          }
        })
      },
  
    },
  }
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>  