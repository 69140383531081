<template>
    <div>
      <!-- table -->
  
      <b-overlay :show="showLoadingo" no-wrap />
      <vue-good-table
        :columns="columnsCodesAssureurs"
        :rows="rowsMembres"
        :rtl="direction"
        style-class="vgt-table condensed"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: pageLength,
          rowsPerPageLabel: '',
          dropdownAllowAll: false,
          perPageDropdownEnabled: false,
          nextLabel: 'Suivant',
          prevLabel: 'Précédent',
          ofLabel: 'de',
          infoFn: params => ``
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->
          <span v-if="props.column.field == 'statut'">
              <template v-if="props.row.utilisateur == 'Oui'">
                  <b-badge variant="light-info">
                      {{ props.row.utilisateur }}
                  </b-badge>
              </template>
              <template v-else>
                  <b-badge variant="light-warning">
                      {{ props.row.utilisateur }}
                  </b-badge>
              </template>
          </span>
          <span v-if="(props.column.field == 'suspend' && props.row.utilisateur == 'Oui')">
              <template v-if="props.row.bloqued == 1">
                <b-badge variant="light-danger" v-b-tooltip.hover title="Compte bloqué">
                  <feather-icon
                    class="text-body align-middle mr-25"
                    icon="AlertOctagonIcon"
                    size="16"
                  />
                </b-badge>
              </template>
              <template v-else>
                <b-badge variant="light-success" v-b-tooltip.hover title="Compte actif">
                  <feather-icon
                    class="text-body align-middle mr-25"
                    icon="AwardIcon"
                    size="16"
                  />
                </b-badge>
              </template>
          </span>
          <span v-if="props.column.field == 'name'">
              {{ props.row.nom }} {{ props.row.prenom }} 
          </span>
          <span v-else-if="props.column.field === 'actions'">
            <span>
              <template v-if="props.row.utilisateur == 'Oui'">
              <b-dropdown
                  dropright
                  no-caret
                  toggle-class="text-decoration-none p-0"
                  variant="link"
              >
                <template v-slot:button-content>
                  <feather-icon
                      class="text-body align-middle mr-25"
                      icon="MoreVerticalIcon"
                      size="16"
                  />
                </template>
              </b-dropdown>
              </template>
            </span>
          </span>
          <span v-else>
                  {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <b-row class="mt-2 align-items-center">
                <b-col md="6" lg="5" xxl="3">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Affichage 1 à
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> de {{ props.total }} entrées </span>
                  </div>
                </b-col>
            </b-row>
          <b-row class="mt-2 align-items-center">
            <b-col>
            </b-col>
            <b-col>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </template>
        <div class="text-center align-middle" slot="emptystate">
          <span>Aucune donn&eacute;e disponible dans le tableau</span>
        </div>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  import { BPagination, BFormGroup, BFormSelect, BFormSelectOption, BRow, BCol, BOverlay,BLink,BDropdown,BDropdownItem,BBadge,VBTooltip} from 'bootstrap-vue'
  import { VueGoodTable } from 'vue-good-table'
  import Ripple from 'vue-ripple-directive'
  import store from '@/store/index'
  
  export default {
    components: {
      VueGoodTable,
      BPagination,
      BFormGroup,
      BFormSelect,
      BFormSelectOption,
      BRow,
      BCol,
      BOverlay,
      BLink,
      BDropdown,
      BDropdownItem,
      BBadge,
      VBTooltip
    },
    directives: {
      Ripple,
      'b-tooltip': VBTooltip,
    },
    data() {
      return {
        showLoadingo: false,
        currentUser: JSON.parse(localStorage.getItem('userData')),
        pageLength: 10,
        dir: false,
        options: [
          { value: null, text: 'Action de masse' },
          { value: 'autre', text: ' --- ' }
        ],
        selected: null,
        columnsCodesAssureurs: [
          { label: 'Interlocuteur', field: 'name', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
          { label: 'Statut  juridique', field: 'statutJuriduque', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
          { label: 'Sous-code', field: 'sousCode', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        ],
        rowsMembres : null
      }
    },
    props: {
      courtierId: {
        type: Number,
        default: null,
        required: false
      },
      idAssureurSelected: {
        type: Number,
        required: true
      },
    },
    created() {
    this.fetchInterlocuteursByAssureur()
     },
    methods: {
        fetchInterlocuteursByAssureur(){
        const courtierId = this.courtierId ? this.courtierId : this.currentUser.courtier_user[0].courtier_id
        const assureurId = this.idAssureurSelected;
        this.$http
        .post('/collaborateur/fetchInterlocuteurs', { courtierId,assureurId })
        .then(res => {
            if (res.data.success) {
                this.rowsMembres = res.data.data
            }
        })
        .catch(err => {
            console.error(err)
        })
        },
    }
     
  }
  </script>
  