<template>
  <div>
    <div class="custom-search d-flex justify-content-end">
      <div v-if="can('Add compliance')" class="me-auto bd-highlight mb-1">
        <b-button v-b-toggle.sidebar-document-conformite v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
          <feather-icon class="mr-50" icon="PlusIcon"/>
          <span class="align-middle">Ajouter un document</span>
        </b-button>
        <actions-document-conformite-vue :collaborateur-options="collaborateurOptions"
                                         :courtier-id="courtierId" :document-conformite-demande="documentConformiteDemande"
                                         :user-id="userId"
                                         @record-document-courtier-compliance-returned="RecordDocumentCourtierComplianceReturned"
        />
      </div>
    </div>

    <b-card v-if="can('List compliances')" :title="isFromMenu ? 'Filtres' : ''" :body-class="isFromMenu ? '' : 'py-0'">
      <div class="custom-search">
        <!-- advance search input -->
        <b-row>
          <b-col md="3">
            <b-form-group label="Type de document" label-for="filtre-type-document">
              <b-form-select id="filtre-type-document" v-model="typeSelected">
                <template #first>
                  <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                </template>
                <b-form-select-option v-for="(type, index) in documentConformiteDemande" :key="index"
                                      :value="type.typeLibelle"
                >{{ type.typeLibelle }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Collaborateur" label-for="filtre-collaborateur">
              <b-form-select id="filtre-collaborateur" v-model="collabortateurSelected">
                <template #first>
                  <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                </template>
                <b-form-select-option v-for="(collaborateur, index) in collaborateurOptions" :key="index"
                                      :value="collaborateur.nom"
                >{{ collaborateur.nom }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <!-- <b-col md="3">
            <b-form-group label="Statut" label-for="filtre-statut">
              <b-form-select id="filtre-statut" v-model="statutSelected" :options="statutOptions">
                <template #first>
                  <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col> -->
          <b-col md="2">
            <b-form-group label="Période" label-for="filtre-term">
              <b-form-select id="filtre-term" v-model="searchTerm" :options="optionsSearchTerm"/>
            </b-form-group>
          </b-col>
          <b-col md="1">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="btn-icon mt-2"
                v-b-tooltip.hover.top="'Tout afficher'"
                variant="primary"
                @click="resetFilter">
                <feather-icon icon="RefreshCwIcon"/></b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- table -->
    <b-overlay :show="showLoading" no-wrap/>
    <vue-good-table v-if="can('List compliances')" :columns="columnsConformite" :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: params => ``
      }" :rows="rowsConformite" :rtl="direction" :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'lignes sélectionnées',
              clearSelectionText: 'Effacer la sélection',
              disableSelectInfo: false,
              selectAllByGroup: false
            }" style-class="vgt-table condensed" @on-selected-rows-change="selectionChanged"
    >
      <template slot="table-row" slot-scope="props">
        <!-- <span v-if="props.column.field === 'statut'">
          <b-badge :variant="statutVariant(props.row.statut)">
            {{ props.row.statut }}
          </b-badge>
        </span> -->
        <!-- Column: Action -->
        <span v-if="props.column.field === 'actions'">
          <span>
            <b-dropdown no-caret toggle-class="text-decoration-none p-0" variant="link">
              <template v-slot:button-content>
                <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16"/>
              </template>
              <b-dropdown-item v-if="can('Visualize document compliance')"
                               @click="displayDocument(props.row.idDocument, props.row.Name, props.row.NomDuDocument)"
              >
                <feather-icon class="mr-50" icon="SearchIcon"/>
                <span>Visualiser</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="can('Delete other documents')" @click="deleteDocument(props.row.idDocument, props.row.vgt_id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Supprimer</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="can('Historize compliance') && props.row.etat === 'Actif'"
                               @click="historiserDocumentConformite(props.row.id, props.row.vgt_id)"
              >
                <feather-icon class="mr-50" icon="ClockIcon"/>
                <span>Historiser</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
        <b-row class="mt-2 align-items-center">
          <b-col>
            <b-input-group>
              <b-form-select v-model="selected" :options="options"/>
              <b-input-group-append>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="doActionMultipleRows">
                  <span>Valider </span>
                  <feather-icon class="mr-50" icon="ArrowRightIcon"/>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col>
            <b-pagination :per-page="pageLength" :total-rows="props.total" :value="1" align="right" first-number
                          last-number next-class="next-item" prev-class="prev-item"
                          @input="value => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div slot="emptystate" class="text-center align-middle">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>

    <b-modal v-if="can('Visualize document compliance')" id="modal-display-document" ref="modal-display-document"
             :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'" :title="document.name" cancel-title="Fermer"
             cancel-variant="outline-secondary"
             ok-title="Télécharger"
             @ok="downloadDocument(document.base64, document.nameToDownload)"
    >
      <form ref="form" :style="{height : document.extensionDocument === 'PDF' ? '80vh' : 'auto', 'overflow': 'hidden'}">
        <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid/>
        <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%" :style="hideToolbar()"/>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  VBTooltip,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  VBToggle
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import ActionsDocumentConformiteVue from './ActionsDocumentConformite.vue'

export default {
  components: {
    VueGoodTable,
    BFormInput,
    BFormSelectOption,
    BOverlay,
    BImg,
    BBadge,
    BPagination,
    BFormGroup,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCard,
    BCardText,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,

    // Ui
    ActionsDocumentConformiteVue
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isFromMenu: {
      type: Boolean,
      default: false,
      required: false,
    },
    isRefreshDataConformite: {
      type: Boolean,
      default: false,
      required: false,
    },
    courtierId: {
      type: Number,
      default: null,
      required: true
    },
    userId: {
      type: Number,
      default: null,
      required: true
    }
  },
  data() {
    return {
      selectionChangedValues: [],
      showLoading: false,
      pageLength: 10,
      dir: false,
      options: [
        {
          value: null,
          text: 'Action de masse'
        },
        {
          value: 'Historiser',
          text: 'Historiser'
        },
        {
          value: 'Supprimer',
          text: 'Supprimer'
        }
      ],
      statutOptions: [
        {
          value: 'EN ATTENTE DE TRANSMISSION',
          text: 'EN ATTENTE DE TRANSMISSION'
        },
        {
          value: 'VALIDE',
          text: 'VALIDE'
        },
        {
          value: 'REFUSE',
          text: 'REFUSE'
        },
        {
          value: 'EN COURS DE TRAITEMENT',
          text: 'EN COURS DE TRAITEMENT'
        },
      ],
      typesOptions: [],
      collaborateurOptions: [],
      statut: [{
        1: 'EN ATTENTE DE TRANSMISSION',
        2: 'VALIDE',
        3: 'REFUSE',
        4: 'EN COURS DE TRAITEMENT',
      },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-info',
        }],
      selected: null,
      columnsConformite: [
        {
          label: 'Famille',
          field: 'FamilleDeDocument',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-2 align-middle h6'
        },
        {
          label: 'Type',
          field: 'TypeDeDocument',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-2 align-middle h6'
        },
        {
          label: 'Collaborateur concerné',
          field: 'NomPrenom',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-2 align-middle h6'
        },
        {
          label: 'Date début ',
          field: 'dateDebut',
          type: 'date',
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-2 align-middle h6'
        },
        {
          label: 'Date fin',
          field: 'dateFin',
          type: 'date',
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-2 align-middle h6'
        },
        // {
        //   label: 'Statut',
        //   field: 'statut',
        //   sortable: false,
        //   thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
        //   tdClass: 'text-center m-0 p-2 align-middle h6'
        // },

        {
          field: 'actions',
          label: 'Actions',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 py-1 px-2 align-middle'
        }
      ],
      optionsSearchTerm: [
        {
          value: 'Actif',
          text: 'En cours',
          selected: true
        },
        {
          value: 'Historique',
          text: 'Historique'
        }
      ],
      DateOptions: [
        {
          value: '2021',
          text: '2021'
        },
        {
          value: '2022',
          text: '2022'
        }
      ],
      rowsConformiteKeeped: [],
      rowsConformite: [],
      documentConformiteDemande: [],
      searchTerm: 'Actif',
      searchStatut: null,
      searchDate: '',
      typeSelected: null,
      collabortateurSelected: null,
      statutSelected: null,
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null
      }
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statutVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'EN ATTENTE DE TRANSMISSION': 'light-primary',
        REFUSE: 'light-danger',
        VALIDE: 'light-success',
        'EN COURS DE TRAITEMENT': 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  watch: {
    // searchTerm: {
    //   deep: true,
    //   handler(val) {
    //     this.rowsConformite = this.rowsConformiteKeeped.filter(item => item.etat === val)
    //   }
    // },
    isRefreshDataConformite:{
      handler(val){
        if (val) {
          this.fetch_documents_courtier_conformite()
          this.$emit('is-data-refreshed', true)
        }
      }
    },
    typeSelected() {
      this.filterData()
    },
    collabortateurSelected() {
      this.filterData()
    },
    statutSelected() {
      this.filterData()
    },
    searchTerm() {
      this.filterData()
    }
    // searchDate: {
    //   deep: true,
    //   handler(val) {
    //     // let dateSelected = moment(val).format('YYYY')
    //     this.rowsConformite = this.rowsConformiteKeeped.filter(item => moment(item.dateDebut, 'DD/MM/YYYY').format('Y').includes(val))
    //   }
    // },
  },
  created() {
    this.fetchDocumentConformite()
    this.fetch_documents_courtier_conformite()
    this.fetchDataCommercialByCourtier()
  },
  methods: {
    deleteDocument(id, vgtId) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer le document ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(confirmed => {
        if (confirmed.value) {
          this.$http
            .delete(`/document/delete_document_courtier/${id}`)
            .then(res => {
              if (res.data.success) {
                const indexDocument = this.rowsConformite.findIndex(item => item.idDocument === id)
                this.rowsConformite.splice(indexDocument, 1)
                const indexDocumentKeep = this.rowsConformiteKeeped.findIndex(item => item.idDocument === id)
                this.rowsConformiteKeeped.splice(indexDocumentKeep, 1)

                this.messageToast(res.data.message, 'Succès', 'success')
              } else {
                this.messageToast(res.data.message, 'Erreur', 'error')
              }
            })
            .catch(error => {
              console.log(error)
              this.messageToast(error.response.data, 'Erreur', 'error')
            })
        }
      })
    },
    fetchDataCommercialByCourtier() {
      this.collaborateurOptions = []
      this.$http
          .post('/prospect/fetchDataCommercialByCourtier', { courtier_id: this.courtierId })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.collaborateurOptions.push({
                  id: el.user_id,
                  nom: el.nom
                })
              })
            }
          })
          .catch(err => {
            this.collaborateurOptions = []
            console.error(err)
          })
    },
    resetFilter() {
      this.typeSelected = null
      this.collabortateurSelected = null
      this.statutSelected = null
      this.searchTerm = 'Actif'
    },
    filterData() {
      // if (!this.typeSelected && !this.collabortateurSelected && !this.statutSelected && !this.termSelected)
      this.rowsConformite = this.rowsConformiteKeeped
      if (this.typeSelected) this.rowsConformite = this.rowsConformiteKeeped.filter(item => item.TypeDeDocument === this.typeSelected)
      if (this.collabortateurSelected) this.rowsConformite = this.rowsConformite.filter(item => item.NomPrenom === this.collabortateurSelected)
      if (this.statutSelected) this.rowsConformite = this.rowsConformite.filter(item => item.statut === this.statutSelected)
      if (this.searchTerm) this.rowsConformite = this.rowsConformite.filter(item => item.etat === this.searchTerm)
    },
    doActionMultipleRows() {
      if (this.selected === 'Historiser') {
        if (this.selectionChangedValues.length > 0) {
          this.historiserMultiplDocumentConformite()
        } else {
          this.messageToast('Aucune ligne sélectionnée.', 'Attention', 'warning')
        }
      }
      else if(this.selected === 'Supprimer'){
        if(this.selectionChangedValues.length > 0){
          this.$swal({
              title: 'Êtes-vous sûr de vouloir supprimer les documents ?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
                .then((result) => {
                  if (result.value) {
                    const selected_doc = [];
                    for (let i = 0; i < this.selectionChangedValues.length; i++) {
                        selected_doc.push(this.selectionChangedValues[i].idDocument)
                    }
                    this.$http
                        .post('document/deleteDocumentCourtierMasse/', { 'documents': selected_doc })
                        .then((res) => {
                          if (res.data.success) {
                            this.resetFilter()
                            this.fetch_documents_courtier_conformite()
                          }
                        })
                        .catch((err) => {
                        })

                    this.$swal({
                      icon: 'success',
                      title: 'Supprimé!',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  }
                })
        }

      } else {
        this.messageToast('Aucune action sélectionnée.', 'Attention', 'warning')
      }
    },
    selectionChanged(val) {
      this.selectionChangedValues = val.selectedRows
    },
    downloadDocument(base64, name) {
      if (base64) {
        const a = document.createElement('a')
        a.href = base64
        a.download = name
        a.click()
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    displayDocument(id, name, NomDuDocument) {
      this.$http
          .get(`/document/generate_base64_for_document/${id}`)
          .then(res => {
            if (res.data.success) {
              this.clearDocumentDisplay()
              if (res.data.data.isDownload) {
                this.downloadDocument(res.data.data.base64, name)
              } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
                this.document.base64 = res.data.data.base64
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = name
                this.document.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
              } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
                console.log(name)
                this.document.base64 = res.data.data.base64
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = name
                this.document.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
                console.log(this.document.name)
                // const pdfWindow = window.open('')
                // pdfWindow.document.write(`<iframe width='100%' height='100%' src='${res.data.data.base64}' />`)
                // this.downloadDocument(res.data.data.base64, name)
              }
            } else {
              this.clearDocumentDisplay()
              this.messageToast(res.data.message, 'Erreur', 'error')
            }
          })
          .catch(err => {
            this.clearDocumentDisplay()
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },
    RecordDocumentCourtierComplianceReturned(data, message) {
      this.rowsConformite.push(data)
      this.rowsConformiteKeeped.push(data)
      this.filterData()
      this.messageToast(message, 'Succès', 'success')
    },
    historiserMultiplDocumentConformite() {
      this.$swal({
        title: 'Etes-vous sûr de vouloir historiser le document?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      })
          .then(confirmed => {
            if (confirmed.value) {
              this.$http
                  .post('/document/historiserDocumentConformitePost', this.selectionChangedValues)
                  .then(res => {
                    if (res.data.success) {
                      // dateFin etat
                      this.selectionChangedValues.forEach(item => {
                        console.log(item.originalIndex)
                        this.rowsConformite[item.originalIndex].etat = 'Historique'
                        this.rowsConformiteKeeped[item.originalIndex].etat = 'Historique'
                        this.rowsConformite[item.originalIndex].dateFin = moment(new Date())
                            .format('DD/MM/YYYY')
                        this.rowsConformiteKeeped[item.originalIndex].dateFin = moment(new Date())
                            .format('DD/MM/YYYY')
                      })

                      this.rowsConformite = this.rowsConformiteKeeped.filter(item => item.etat === this.searchTerm)
                      this.messageToast(res.data.message, 'Succès', 'success')
                    } else {
                      this.messageToast(res.data.message, 'Erreur', 'error')
                    }
                  })
                  .catch(error => {
                    console.log(error)
                    this.messageToast(error.response.data, 'Erreur', 'error')
                  })
            }
          })
    },
    fetchDocumentConformite() {
      this.showLoading = true
      this.documentConformiteDemande = []
      this.$http
          .get('/document_conformite/fetchDocumentConformite')
          .then(res => {
            if (res.data.success) {
              this.documentConformiteDemande = res.data.data
              this.showLoading = false
            } else {
              this.showLoading = false
              this.messageToast('Nous n\'avons pas pu trouver la ressource que vous avez demandée.', 'Erreur', 'warning')
            }
          })
          .catch(err => {
            this.showLoading = false
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },

    fetch_documents_courtier_conformite() {
      this.showLoading = true
      this.rowsConformite = []
      this.rowsConformiteKeeped = []
      this.$http
          .get(`/document/fetch_documents_courtier_conformite/${this.courtierId}`)
          .then(res => {
            if (res.data.success) {
              this.rowsConformiteKeeped = res.data.data
              this.rowsConformite = res.data.data
              this.rowsConformite = this.rowsConformite.map(obj => ({ ...obj, NomPrenom: obj.nomPrenom, nomPrenom: undefined }));
              this.rowsConformiteKeeped = this.rowsConformiteKeeped.map(obj => ({ ...obj, NomPrenom: obj.nomPrenom, nomPrenom: undefined }));
              console.log('res.data.data')
              console.log(res.data.data)
              this.rowsConformite = this.rowsConformiteKeeped.filter(item => item.etat === this.searchTerm)
              this.filterData()
              this.showLoading = false
            } else {
              this.showLoading = false
              this.messageToast('Nous n\'avons pas pu trouver la ressource que vous avez demandée.', 'Erreur', 'warning')
            }
          })
          .catch(err => {
            this.showLoading = false
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },
    historiserDocumentConformite(documentCourtierId, vgtId) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir historiser le document?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      })
          .then(confirmed => {
            if (confirmed.value) {
              this.$http
                  .put(`/document/historiserDocumentConformite/${documentCourtierId}`)
                  .then(res => {
                    if (res.data.success) {
                      // dateFin etat
                      this.rowsConformite[vgtId].etat = 'Historique'
                      this.rowsConformiteKeeped[vgtId].etat = 'Historique'

                      this.rowsConformite[vgtId].dateFin = moment(new Date())
                          .format('DD/MM/YYYY')
                      this.rowsConformiteKeeped[vgtId].dateFin = moment(new Date())
                          .format('DD/MM/YYYY')
                      this.rowsConformite = this.rowsConformiteKeeped.filter(item => item.etat === this.searchTerm)
                      this.messageToast(res.data.message, 'Succès', 'success')
                    } else {
                      this.messageToast(res.data.message, 'Erreur', 'error')
                    }
                  })
                  .catch(error => {
                    console.log(error)
                    this.messageToast(error.response.data, 'Erreur', 'error')
                  })
            }
          })
    }
  }
}
</script>
