<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center" v-if="can('Add other documents')">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" v-b-toggle.sidebar-document-cabinet>
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Ajouter un document</span>
          </b-button>
        </div>
      </b-form-group>
      <actions-document-vue v-if="can('Add other documents')" id="cabinet" :courtier-id="courtierId" :user-id="userId" @record-document-courtier-returned="recordDocumentCourtierReturned" :collaborateur-options="collaborateurOptions" />
    </div>

    <b-card :title="isFromMenu ? 'Filtres' : ''" :body-class="isFromMenu ? '' : 'py-0'">
      <div class="custom-search">
        <!-- advance search input -->
        <b-row>
          <b-col md="3">
            <b-form-group label="Type de document" label-for="filtre-type-document">
              <b-form-select id="filtre-type-document" v-model="typeSelected">
                <template #first>
                  <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                </template>
                <b-form-select-option v-for="type in typesAutreDocument" :key="type.id"
                                      :value="type.type"
                >{{ type.type }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Collaborateur" label-for="filtre-collaborateur">
              <b-form-select id="filtre-collaborateur" v-model="collabortateurSelected">
                <template #first>
                  <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                </template>
                <b-form-select-option v-for="(collaborateur, index) in collaborateurOptions" :key="index"
                                      :value="collaborateur.nom"
                >{{ collaborateur.nom }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="1">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="btn-icon mt-2"
                v-b-tooltip.hover.top="'Tout afficher'"
                variant="primary"
                @click="resetFilter">
                <feather-icon icon="RefreshCwIcon"/></b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- table -->
    <b-overlay :show="showLoadingo" no-wrap />
    <vue-good-table
        v-if="can('List other documents')"
      :columns="columnsAutresDocuments"
      :rows="rowsAutresDocuments"
      :rtl="direction"
      style-class="vgt-table condensed"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: params => ``
      }"
      :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'lignes sélectionnées',
            clearSelectionText: 'Effacer la sélection',
            disableSelectInfo: false,
            selectAllByGroup: false
          }"
      @on-selected-rows-change="selectionChanged"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'actions'">
          <span>
            <b-dropdown variant="link" toggle-class="text-decoration-none p-0" no-caret>
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
              </template>
              <b-dropdown-item  @click="displayDocument(props.row.id, props.row.Name, props.row.NomDuDocument)">
                <feather-icon icon="SearchIcon" class="mr-50" />
                <span>Visualiser</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="can('Delete other documents')" @click="deleteDocument(props.row.id, props.row.vgt_id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
        <b-row class="mt-2 align-items-center">
          <b-col md="4">
            <b-input-group>
              <b-form-select v-model="selected" :options="options" />
              <b-input-group-append>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                  @click="action_masse(selected)">
                  <span>Valider </span>
                  <feather-icon icon="ArrowRightIcon" class="mr-50" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div slot="emptystate" class="text-center align-middle">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>

    <b-modal v-if="can('Visualize document compliance')" id="modal-display-document" ref="modal-display-document"
             :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'" :title="document.name" cancel-title="Fermer"
             cancel-variant="outline-secondary"
             ok-title="Télécharger"
             @ok="downloadDocument(document.base64, document.nameToDownload)"
    >
      <form ref="form" :style="{height : document.extensionDocument === 'PDF' ? '80vh' : 'auto', 'overflow': 'hidden'}">
        <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid/>
        <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%" :style="hideToolbar()"/>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { BPagination, BFormGroup, BFormSelect, VBTooltip, BFormSelectOption, BDropdown, BDropdownItem, BRow, BCol, BButton, BInputGroup, BInputGroupAppend, BOverlay, BModal, BImg, VBToggle, BCard } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import documentService from '@/shared/services/documentService'
import ActionsDocumentVue from './ActionsDocument.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BDropdown,
    BDropdownItem,
    BRow,
    BCard,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    BModal,
    BImg,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,

    // UI
    ActionsDocumentVue
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    courtierId: {
      type: Number,
      default: null,
      required: true
    },
    userId: {
      type: Number,
      default: null,
      required: true
    },
    isFromMenu: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      showLoadingo: false,
      pageLength: 10,
      dir: false,
      options: [
        { value: null, text: 'Action de masse' },
        { value: 'Supprimer', text: ' Supprimer ' }
      ],
      selected: null,
      collaborateurOptions: [],
      columnsAutresDocuments: [
        { label: 'Famille', field: 'FamilleDeDocument', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Type', field: 'TypeDeDocument', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Collaborateur concerné', field: 'NomPrenom', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        // { label: 'Description', field: 'Description', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: "Date de l'ajout", field: 'CreeLe', type: 'date', dateInputFormat: 'dd/MM/yyyy', dateOutputFormat: 'dd/MM/yyyy', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { field: 'actions', label: 'Actions', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' }
      ],
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null
      },
      rowsAutresDocuments: [],
      rowsAutresDocumentsKeeped:[],
      typesAutreDocument: [],
      typeSelected: null,
      collabortateurSelected: null,
      selectedrows_masse: []
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  created() {
    this.fetch_documents_courtier()
    this.fetchDataCommercialByCourtier()
    this.fetchDocumentNatureWithTypesNonConformite()
  },
  watch: {
    typeSelected() {
      this.filterData()
    },
    collabortateurSelected() {
      this.filterData()
    }
  },
   updated() {
   this.AutreDocumentNom()
  },
  methods: {
    selectionChanged(params) {
      this.selectedrows_masse = params
    },
    action_masse(action) {
      switch (action) {
        case 'Supprimer':
          if (this.selectedrows_masse.selectedRows.length > 0) {
            this.$swal({
              title: 'Êtes-vous sûr de vouloir supprimer les documents ?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
                .then((result) => {
                  if (result.value) {
                    const selected_doc = [];
                    for (let i = 0; i < this.selectedrows_masse.selectedRows.length; i++) {
                        selected_doc.push(this.selectedrows_masse.selectedRows[i].id)
                    }
                    this.$http
                        .post('document/deleteDocumentCourtierMasse/', { 'documents': selected_doc })
                        .then((res) => {
                          if (res.data.success) {
                            // for (let i = 0; i < this.selectedrows_masse.selectedRows.length; i++) {
                            //   const indexDocument = this.rowsAutresDocuments.findIndex(item => item.id === this.selectedrows_masse.selectedRows[i].id)
                            //   this.rowsAutresDocuments.splice(indexDocument, 1)
                            // }
                            this.resetFilter()
                            this.fetch_documents_courtier()
                          }
                        })
                        .catch((err) => {
                        })

                    this.$swal({
                      icon: 'success',
                      title: 'Supprimé!',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  }
                })
          }
          break
          default:

      }
    },
    recordDocumentCourtierReturned(data, message) {
      this.rowsAutresDocuments.push(data)
      

      
      this.messageToast(message, 'Succès', 'success')
    },
    AutreDocumentNom() {
      const idsTypeAutre = [25, 45, 52, 53, 54, 55]
      this.rowsAutresDocuments.forEach( item => {
        if (idsTypeAutre.includes(item.IdDeTypeDeDocument)) {
          item.TypeDeDocument =  item.NomDuDocument
        }
      })
      
      this.rowsAutresDocumentsKeeped.forEach( item => {
        if (idsTypeAutre.includes(item.IdDeTypeDeDocument)) {
          item.TypeDeDocument =  item.NomDuDocument
        }
      })
    },
    downloadDocument(base64, name) {
      if (base64) {
        const a = document.createElement('a')
        a.href = base64
        a.download = name
        a.click()
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    displayDocument(id, name, NomDuDocument) {
      this.$http
        .get(`/document/generate_base64_for_document/${id}`)
        .then(res => {
          if (res.data.success) {
            this.clearDocumentDisplay()
            if (res.data.data.isDownload) {
              this.downloadDocument(res.data.data.base64, name)
            } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
              this.document.base64 = res.data.data.base64
              this.document.name = NomDuDocument || name
              this.document.nameToDownload = name
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document'].show()
            } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
               this.document.base64 = res.data.data.base64
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = name
                this.document.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
              // const pdfWindow = window.open('')
              // pdfWindow.document.write(`<iframe width='100%' height='100%' src='${res.data.data.base64}' />`)
              // this.downloadDocument(res.data.data.base64, name)
            }
          } else {
            this.clearDocumentDisplay()
            this.messageToast(res.data.message, 'Erreur', 'error')
          }
        })
        .catch(err => {
          this.clearDocumentDisplay()
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    },
    deleteDocument(id, vgtId) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer le document ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(confirmed => {
        if (confirmed.value) {
          this.$http
            .delete(`/document/delete_document_courtier/${id}`)
            .then(res => {
              if (res.data.success) {
                const indexDocument = this.rowsAutresDocuments.findIndex(item => item.id === id)
                this.rowsAutresDocuments.splice(indexDocument, 1)
                this.messageToast(res.data.message, 'Succès', 'success')
              } else {
                this.messageToast(res.data.message, 'Erreur', 'error')
              }
            })
            .catch(error => {
              console.log(error)
              this.messageToast(error.response.data, 'Erreur', 'error')
            })
        }
      })
    },
    fetch_documents_courtier() {
      this.showLoadingo = true
      const { courtierId } = this
      this.rowsConformite = []
      this.rowsConformiteKeeped = []
      this.$http
        .post('/document/fetch_documents_courtier', { courtier_id: courtierId })
        .then(res => {
          this.rowsAutresDocumentsKeeped = res.data.data
          this.rowsAutresDocuments = res.data.data
          this.showLoadingo = false
          this.AutreDocumentNom()
        })
        .catch(err => {
          this.showLoadingo = false
          console.error(err)
        })
    },
    fetchDataCommercialByCourtier() {
      this.collaborateurOptions = []
      this.$http
          .post('/prospect/fetchDataCommercialByCourtier', { courtier_id: this.courtierId })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.collaborateurOptions.push({
                  id: el.user_id,
                  nom: el.nom
                })
              })
            }
          })
          .catch(err => {
            this.collaborateurOptions = []
            console.error(err)
          })
    },
    resetFilter() {
      this.typeSelected = null
      this.collabortateurSelected = null
    },
    filterData() {
      this.rowsAutresDocuments = this.rowsAutresDocumentsKeeped
      if (this.typeSelected) this.rowsAutresDocuments = this.rowsAutresDocumentsKeeped.filter(item => item.TypeDeDocument.includes(this.typeSelected))
      if (this.collabortateurSelected) this.rowsAutresDocuments = this.rowsAutresDocuments.filter(item => item.NomPrenom === this.collabortateurSelected)
    },
    fetchDocumentNatureWithTypesNonConformite() {
        this.typesAutreDocument = []
        this.$http
          .post('/document_nature/fetch_document_nature_with_types_non_conformite')
          .then(response => {
            response.data.forEach(el => {
                el.documentype.forEach(DocumentType => {
                  this.typesAutreDocument.push(DocumentType)
                })
            })
          })
          .catch(errors => {
            console.error(errors)
            this.typesAutreDocument = []
          });
    }
  }
}
</script>
