<template>
  <div>
    <div
      class="custom-search d-flex justify-content-start"
      v-if="can('Create note')"
    >
      <b-form-group>
        <div class="d-flex align-items-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            v-b-toggle.sidebar-note
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Ajouter une note</span>
          </b-button>
        </div>
      </b-form-group>
      <!-- component just for add notes -->
      <actions-note
        :courtier-id="courtierId"
        :user-id="userId"
        :my-note="myNote"
        @record-note-returned="recordNoteReturned"
      />
      <!-- component just for edit notes -->

      <b-sidebar
        id="sidebar-note-edit"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        ref="sideBarNoteEdit"
        shadow
        backdrop
        no-header
        right
        no-close-on-backdrop
      >
        <template #default="{ hide }">
          <!-- Header -->
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <!-- <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Modifier courtier</h5> -->
            <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
              Modifier note
            </h5>

            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
          <validation-observer ref="noteEditComposeRules">
            <b-form
              class="p-2"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <!-- Note Quill -->
              <b-form-group label="Note" label-for="note">
                <validation-provider
                  #default="{ errors }"
                  name="Note"
                  rules="required"
                >
                  <quill-editor
                    id="h-Note"
                    v-model="myNote.note"
                    :options="snowOption"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  @click="updateNote"
                >
                  Modifier
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </template>
      </b-sidebar>
    </div>

    <div>
      <vue-perfect-scrollbar
        v-if="can('List notes')"
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
        style="height: calc(100% - calc(3.49rem + 7.5rem))"
      >
        <b-overlay :show="showLoading" no-wrap />
        <div v-if="notes.length > 0">
          <b-card
            v-for="(note, index) in notes"
            :key="note.id"
            class="position-static m-0 rounded-0 border-bottom border-top p-0"
            body-class="p-2"
            :class="index % 2 === 0 ? 'bg-light' : ''"
          >
            <b-row>
              <b-col cols="8">
                <b-card-title class="mb-0"> {{ note.userName }} </b-card-title>
                <b-card-text class="mb-0" v-html="note.note"></b-card-text>
                <b-card-text>
                  <small class="text-muted">{{ note.date }}</small>
                </b-card-text>
              </b-col>
              <b-col cols="4" class="text-right">
                <span style="cursor: pointer" @click="deleteNote(note.id)"
                  ><feather-icon size="20" icon="TrashIcon"
                /></span>
                <span
                  v-b-toggle.sidebar-note-edit
                  @click="editNote(note)"
                  style="cursor: pointer"
                  ><feather-icon
                    style="cursor: ponter"
                    size="20"
                    icon="EditIcon"
                /></span>
              </b-col>
            </b-row>
          </b-card>
        </div>

        <div class="no-results" v-else>
          <h5>Aucune note trouvé</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BCard,
  BCardText,
  BCardTitle,
  VBToggle,
  BOverlay,
  BRow,
  BCol,
  BSidebar,
  BForm
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { filterTags, formatDateToMonthShort } from "@core/utils/filter";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import ActionsNote from "./ActionsNote.vue";
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    // BTV
    BSidebar,
    BButton,
    BFormGroup,
    BCard,
    BCardText,
    BCardTitle,
    BOverlay,
    BRow,
    BCol,
    BForm,
    // 3rd Party
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
    VuePerfectScrollbar,

    // UI
    quillEditor,
    ActionsNote,
      // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  props: {
    userId: {
      type: Number,
      default: null,
      required: true,
    },
    courtierId: {
      type: Number,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      required,
      showLoading: false,
      notes: [],
      myNote: {
        id: null,
        note: null,
         userId: this.userId,
        courtierId: this.courtierId
      },
       snowOption: {
        theme: 'snow',
        placeholder: 'Écrivez une note...'
      },
    };
  },
  created() {
    this.fetchNoteByCourtier();
  },

  methods: {
     // Methode additional
    hideSideBare() {
      this.$refs.sideBarNoteEdit.hide()
    },
    editNote(note) {
      this.myNote.id = note.id;
      this.myNote.note = note.note;

      // console.log(this.myNote);
    },
    updateNote() {
      // console.log(this.myNote);

       this.$refs.noteEditComposeRules.validate().then(success => {
        if (success) {
          // update or create note
          this.$http
            .post('/note/actionNewNoteCourtier', this.myNote)
            .then(res => {
              if (res.data.success) {
                this.recordUpdatedNoteReturned(res.data.data, res.data.message)
                this.myNote.note = null
                this.myNote.id = null
                this.$refs.noteEditComposeRules.reset()
                this.hideSideBare()
              }
            })
            .catch(err => {
              this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
              console.error(err)
            })
        }
      })
    },
    recordNoteReturned(data, message) {
      this.notes.unshift(data);
      this.messageToast(message, "Succès", "success");
    },
    recordUpdatedNoteReturned(data, message) {
      let index = this.notes.findIndex(note=> note.id==data.id);
      this.notes[index].note = data.note;
      this.messageToast(message, "Succès", "success");
      
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
    fetchNoteByCourtier() {
      this.showLoading = true;
      this.notes = [];
      this.$http
        .get(`/note/fetchNoteByCourtier/${this.courtierId}`)
        .then((res) => {
          if (res.data.success) {
            this.notes = res.data.data;
            this.showLoading = false;
          } else {
            this.showLoading = false;
            this.messageToast(
              "Nous n'avons pas pu trouver la ressource que vous avez demandée.",
              "Erreur",
              "warning"
            );
          }
        })
        .catch((err) => {
          this.showLoading = false;
          this.messageToast(err.response.data.errors[0], "Erreur", "error");
          console.error(err);
        });
    },
    deleteNote(id){
       this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer cette note ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$http
            .delete(`note/deleteNoteCourtier/${id}`)
            .then(res => {
              if(res.data.success){
                this.notes.forEach((note, index)=>{
                    if(note.id == id)
                    {
                      this.notes.splice(index, 1)
                    }
                  })
              }
              this.messageToast(res.data.message, 'Succès', 'success')
            })
            .catch(error => {
              console.log(error)
              this.messageToast(error.response.data, 'Erreur', 'error')
            })
        }
      })
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    return {
      perfectScrollbarSettings,

      formatDateToMonthShort,
      filterTags,
    };
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';

</style>