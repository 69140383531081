<template>
  <div>
    <b-row>
      <b-col md="6" sm="12">
        <b-card bg-variant="transparent" body-class="p-2" border-variant="primary" header="Juridique"
                header-bg-variant="primary" header-class="py-0 px-1 font-weight-bolder" header-text-variant="white"
        >
          <template #header>
            <h6 class="mb-0 font-weight-bolder text-white">Votre structure</h6>
            <b-button v-if="can('Update informations legal broker')" v-b-toggle.sidebar-juridique
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'" class="btn-icon rounded-circle text-white"
                      variant="flat-success"
            >
              <feather-icon icon="Edit3Icon" size="16"/>
            </b-button>
          </template>
          <b-card-text>
            <!-- <b-list-group>
              <b-list-group-item class="d-flex justify-content-between bg-transparent border-0">
                <span class="mr-1">
                  <feather-icon icon="CircleIcon" size="16" />
                  <span
                    class="ml-1">{{ $_.get(currentUser.courtier_user[0].courtier.personne_morale, 'forme_juridique', null) }}</span>
                </span>
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between bg-transparent border-0">
                <span class="mr-1">
                  <feather-icon icon="CircleIcon" size="16" />
                  <span class="ml-1">Crée le {{
                              changeFormatDateLong($_.get(currentUser.courtier_user[0].courtier.personne_morale, 'date_creation', null))
                            }}</span>
                </span>
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between bg-transparent border-0">
                <span class="mr-1">
                  <feather-icon icon="CircleIcon" size="16" />
                  <span class="ml-1">Numéro d'orias
                    {{ $_.get(currentUser.courtier_user[0].courtier, 'numero_orias', null) }}</span>
                </span>
              </b-list-group-item>

              <b-list-group-item v-if="currentUser.courtier_user[0].courtier.association_autoregulation_id"
                class="d-flex justify-content-between bg-transparent border-0">
                <div class="d-flex bd-highlight">
                  <div class="mr-1 flex-shrink-1 bd-highlight">
                    <feather-icon icon="CircleIcon" size="16" />
                  </div>
                  <div class="w-100 bd-highlight d-flex flex-column">
                    <div class="bd-highlight">Adhérent à l'association d'autorégulation
                      {{ $_.get(currentUser.courtier_user[0].courtier.association_autoregulation, 'libelle', null) }}
                      sous numéro {{ $_.get(currentUser.courtier_user[0].courtier, 'numero_adherent_endya', null) }}
                    </div>
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group> -->
            <b-row>
              <b-col class="mb-1" md="12">
                {{
                  $_.get(currentUser.courtier_user[0].courtier.personne_morale, 'forme_juridique', null) ? $_.get(currentUser.courtier_user[0].courtier.personne_morale, 'forme_juridique', null) : ''
                }}
                {{
                  $_.get(currentUser.courtier_user[0].courtier.personne_morale, 'date_creation', null) ? 'créée le ' + changeFormatDateLong($_.get(currentUser.courtier_user[0].courtier.personne_morale, 'date_creation', null)) : ''
                }}
              </b-col>
              <b-col v-if="$_.get(currentUser.courtier_user[0].courtier, 'numero_orias', null)" md="12">
                <b-row>
                  <b-col md="3">
                    N° ORIAS :
                  </b-col>
                  <b-col md="9">
                    {{ $_.get(currentUser.courtier_user[0].courtier, 'numero_orias', null) }}
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="$_.get(currentUser.courtier_user[0].courtier, 'personne_morale.siret', null)" md="12">
                <b-row>
                  <b-col md="3">
                    N° SIRET :
                  </b-col>
                  <b-col md="9">
                    {{ $_.get(currentUser.courtier_user[0].courtier, 'personne_morale.siret', null) }}
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="$_.get(currentUser.courtier_user[0].courtier, 'personne_morale.num_rcs', null)" md="12">
                <b-row>
                  <b-col md="3">
                    Lieu RCS :
                  </b-col>
                  <b-col md="9">
                    {{ $_.get(currentUser.courtier_user[0].courtier, 'personne_morale.num_rcs', null) }}
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="$_.get(currentUser.courtier_user[0].courtier, 'personne_morale.capital_social', null)"
                     md="12"
              >
                <b-row>
                  <b-col md="3">
                    Capital :
                  </b-col>
                  <b-col md="9">
                    {{ $_.get(currentUser.courtier_user[0].courtier, 'personne_morale.capital_social', null) }}
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="$_.get(currentUser.courtier_user[0].courtier, 'personne_morale.effectif', null)" md="12">
                <b-row>
                  <b-col md="3">
                    Effectif :
                  </b-col>
                  <b-col md="9">
                    {{ $_.get(currentUser.courtier_user[0].courtier, 'personne_morale.effectif', null) }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-text>
          <update-cabinet-juridique v-if="can('Update informations legal broker')"
                                    :courtier="$_.get(currentUser.courtier_user[0], 'courtier', null)"
                                    @record-courtier-juridique-returned="RecordCourtierJuridiqueReturned"
          />
        </b-card>
        <b-card  bg-variant="transparent" body-class="p-2" border-variant="primary"
                class="mb-0" header="Interlocuteurs" header-bg-variant="primary"
                header-class="py-0 px-1 font-weight-bolder" header-text-variant="white"
        >
          <template #header>
            <h6 class="mb-0 font-weight-bolder text-white">Vos contacts</h6>
            <b-button v-if="can('Update informations legal broker')"
                      class="btn-icon rounded-circle text-white" style="opacity:0" variant="flat-success"
            >
              <feather-icon icon="Edit3Icon" size="16"/>
            </b-button>
          </template>
          <b-card-text  class="mb-0">
            <b-row class="mb-2">
              <b-col md="5">
                <b-button
                    v-if="can('Update user')"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="btn-icon rounded-circle"
                    variant="flat-primary"
                    @click="changeCollaboratorNotion(interlocuteurs&& interlocuteurs.interlocuteur && interlocuteurs.interlocuteur.id?interlocuteurs.interlocuteur.id:null , 'isPrincipal')"
                >
                  <feather-icon icon="Edit3Icon"/>
                </b-button>
                Administrateur BubbleIn :
              </b-col>
              <b-col md="7" v-if="interlocuteurs && interlocuteurs.interlocuteur">
                {{
                  $_.get(interlocuteurs.interlocuteur.user.personne_physique, 'civilite', null) + ' ' + $_.get(interlocuteurs.interlocuteur.user.personne_physique, 'nom', null) + ' ' + $_.get(interlocuteurs.interlocuteur.user.personne_physique, 'prenom', null)
                }}
                <br>
                {{ $_.get(interlocuteurs.interlocuteur.user.personne_physique.moyen_contact, 'tel') && $_.get(interlocuteurs.interlocuteur.user.personne_physique.moyen_contact, 'indicatif_tel') ?  '('+$_.get(interlocuteurs.interlocuteur.user.personne_physique.moyen_contact, 'indicatif_tel')+') '+$_.get(interlocuteurs.interlocuteur.user.personne_physique.moyen_contact, 'tel').substring(1):$_.get(interlocuteurs.interlocuteur.user.personne_physique.moyen_contact, 'tel')}}
                <br>
                {{ $_.get(interlocuteurs.interlocuteur.user.personne_physique.moyen_contact, 'email', null) }}
              </b-col>
              <!-- <b-col sm="12" md="12">
              <b-row  v-if="interlocuteurs.interlocuteur">
                <b-col cols="4" class="pb-2">
                  {{ $_.get(interlocuteurs.interlocuteur.user.personne_physique, 'nom', null) + ' ' + $_.get(interlocuteurs.interlocuteur.user.personne_physique, 'prenom', null)  }}
                </b-col>
                <b-col cols="8" class="pb-2">
                  {{ $_.get(interlocuteurs.interlocuteur.user.qualification[0], 'fonction', null) }}
                </b-col>
                <b-col cols="4" class="pb-2">
                  {{ $_.get(interlocuteurs.interlocuteur.user.personne_physique.moyen_contact, 'tel', null)  }}
                </b-col>
                <b-col cols="8" class="pb-2">
                  {{ $_.get(interlocuteurs.interlocuteur.user.personne_physique.moyen_contact, 'email', null)  }}
                </b-col>
              </b-row>
            </b-col> -->
            </b-row>
            <!-- <b-list-group>
                <b-card class="mb-0">
                 <b-card-header class="p-0 pb-2">
                     <h3 class="mb">Dirigeant</h3>
                 </b-card-header>
                 <b-card-text>
                   <b-row>
                     <b-col sm="12" md="12">
                       <b-row v-if="interlocuteurs.dirigeant">
                         <b-col cols="12" class="pb-2">
                           {{ $_.get(interlocuteurs.interlocuteur.user.courtier, 'nom_dirigeant', null) + ' ' + $_.get(interlocuteurs.interlocuteur.user.courtier[0], 'prenom_dirigeant', null)  }}
                         </b-col>
                         <b-col cols="4" class="pb-2" v-show="false">
                           {{ $_.get(interlocuteurs.dirigeant.user.personne_physique.moyen_contact, 'tel', null)  }}
                         </b-col>
                         <b-col cols="8" class="pb-2" v-show="false">
                           {{ $_.get(interlocuteurs.dirigeant.user.personne_physique.moyen_contact, 'email', null)  }}
                         </b-col>
                       </b-row>
                     </b-col>
                   </b-row>
                 </b-card-text>
               </b-card>
               <b-card >-->
            <!-- <b-card-header class="p-0 pb-2">
                    <h3 class="mb">Interlocuteur du cabinet</h3>
                </b-card-header>
                <b-card-text>

                </b-card-text>
              </b-card>
            </b-list-group>-->


            <b-row class="mb-2">
              <b-col md="5">
                <b-button
                    v-if="can('Update user')"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="btn-icon rounded-circle"
                    variant="flat-primary"
                    @click="changeCollaboratorNotion(correspondantTracfin && correspondantTracfin.id ? correspondantTracfin.id:null , 'correspondant_tracfin')"
                >
                  <feather-icon icon="Edit3Icon"/>
                </b-button>
                Correspondant Tracfin :
              </b-col>
              <b-col md="7" v-if="correspondantTracfin">
                {{
                  $_.get(correspondantTracfin.user.personne_physique, 'civilite', null) + ' ' + $_.get(correspondantTracfin.user.personne_physique, 'nom', null) + ' ' + $_.get(correspondantTracfin.user.personne_physique, 'prenom', null)
                }}
                <br>
                {{ $_.get(correspondantTracfin.user.personne_physique.moyen_contact, 'tel') && $_.get(correspondantTracfin.user.personne_physique.moyen_contact, 'indicatif_tel') ?  '('+$_.get(correspondantTracfin.user.personne_physique.moyen_contact, 'indicatif_tel')+') '+$_.get(correspondantTracfin.user.personne_physique.moyen_contact, 'tel').substring(1):$_.get(correspondantTracfin.user.personne_physique.moyen_contact, 'tel')}}
                <br>
                {{ $_.get(correspondantTracfin.user.personne_physique.moyen_contact, 'email', null) }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col md="5">
                <b-button
                    v-if="can('Update user')"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="btn-icon rounded-circle"
                    variant="flat-primary"
                    @click="changeCollaboratorNotion(declarantTracfin && declarantTracfin.id?declarantTracfin.id:null , 'declarant_tracfin')"
                >
                  <feather-icon icon="Edit3Icon"/>
                </b-button>
                Déclarant Tracfin :
              </b-col>
              <b-col md="7" v-if="declarantTracfin">
                {{
                  $_.get(declarantTracfin.user.personne_physique, 'civilite', null) + ' ' + $_.get(declarantTracfin.user.personne_physique, 'nom', null) + ' ' + $_.get(declarantTracfin.user.personne_physique, 'prenom', null)
                }}
                <br>
                {{ $_.get(declarantTracfin.user.personne_physique.moyen_contact, 'tel') && $_.get(declarantTracfin.user.personne_physique.moyen_contact, 'indicatif_tel') ?  '('+$_.get(declarantTracfin.user.personne_physique.moyen_contact, 'indicatif_tel')+') '+$_.get(declarantTracfin.user.personne_physique.moyen_contact, 'tel').substring(1):$_.get(declarantTracfin.user.personne_physique.moyen_contact, 'tel')}}
                <br>
                {{ $_.get(declarantTracfin.user.personne_physique.moyen_contact, 'email', null) }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col md="5">
                <b-button
                    v-if="can('Update user')"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="btn-icon rounded-circle"
                    variant="flat-primary"
                    @click="changeCollaboratorNotion(dpo && dpo.id?dpo.id:null , 'dpo')"
                >
                  <feather-icon icon="Edit3Icon"/>
                </b-button>
                DPO :
              </b-col>
              <b-col md="7" v-if="dpo">
                {{
                  $_.get(dpo.user.personne_physique, 'civilite', null) + ' ' + $_.get(dpo.user.personne_physique, 'nom', null) + ' ' + $_.get(dpo.user.personne_physique, 'prenom', null)
                }}
                <br>
                {{ $_.get(dpo.user.personne_physique.moyen_contact, 'tel') && $_.get(dpo.user.personne_physique.moyen_contact, 'indicatif_tel') ?  '('+$_.get(dpo.user.personne_physique.moyen_contact, 'indicatif_tel')+') '+$_.get(dpo.user.personne_physique.moyen_contact, 'tel').substring(1):$_.get(dpo.user.personne_physique.moyen_contact, 'tel')}}
                <br>
                {{ $_.get(dpo.user.personne_physique.moyen_contact, 'email', null) }}
              </b-col>
            </b-row>


            
          </b-card-text>
        </b-card>
        <b-card v-show="false" bg-variant="transparent" body-class="p-2" border-variant="primary" header="Finance"
                header-bg-variant="primary" header-class="py-0 px-1 font-weight-bolder" header-text-variant="white"
        >
          <template #header>
            <h6 class="mb-0 font-weight-bolder text-white">Finance</h6>
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" class="btn-icon rounded-circle text-white"
                      variant="flat-success"
            >
              <feather-icon icon="Edit3Icon" size="16"/>
            </b-button>
          </template>
          <b-card-text>
            <vue-good-table
                :columns="columnsFinance"
                :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 5,
                rowsPerPageLabel: '',
                dropdownAllowAll: false,
                perPageDropdownEnabled: false,
                nextLabel: 'Suivant',
                prevLabel: 'Précédent',
                ofLabel: 'de',
                infoFn: params => ``
              }"
                :rows="rowsFinance"
                :rtl="direction"
                style-class="vgt-table condensed"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Action -->
                <span v-if="props.column.field === 'actions'">
                  <span>
                    <b-dropdown no-caret toggle-class="text-decoration-none" variant="link">
                      <template v-slot:button-content>
                        <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16"/>
                      </template>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="Edit2Icon"/>
                        <span>Éditer</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="PaperclipIcon"/>
                        <span>Joindre</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="TrashIcon"/>
                        <span>Supprimer</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
              </template>
              <div slot="emptystate" class="text-center align-middle">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
              </div>
            </vue-good-table>
          </b-card-text>
        </b-card>
        <b-card v-show="false" bg-variant="transparent" body-class="p-2" border-variant="primary" header="Actionnariat"
                header-bg-variant="primary" header-class="py-0 px-1 font-weight-bolder" header-text-variant="white"
        >
          <template #header>
            <h6 class="mb-0 font-weight-bolder text-white">Actionnariat</h6>
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" class="btn-icon rounded-circle text-white"
                      variant="flat-success"
            >
              <feather-icon icon="Edit3Icon" size="16"/>
            </b-button>
          </template>
          <b-card-text>
            <vue-good-table
                :columns="columnsActionnariat"
                :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 5,
                rowsPerPageLabel: '',
                dropdownAllowAll: false,
                perPageDropdownEnabled: false,
                nextLabel: 'Suivant',
                prevLabel: 'Précédent',
                ofLabel: 'de',
                infoFn: params => `sss`
              }"
                :rows="rowsActionnariat"
                :rtl="direction"
                style-class="vgt-table condensed"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Action -->
                <span v-if="props.column.field === 'actions'">
                  <span>
                    <b-dropdown no-caret toggle-class="text-decoration-none" variant="link">
                      <template v-slot:button-content>
                        <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16"/>
                      </template>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="Edit2Icon"/>
                        <span>Éditer</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="PaperclipIcon"/>
                        <span>Joindre</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="TrashIcon"/>
                        <span>Supprimer</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
              </template>
              <div slot="emptystate" class="text-center align-middle">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
              </div>
            </vue-good-table>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col md="6" sm="12">
        <b-card v-show="false" bg-variant="transparent" body-class="p-2" border-variant="primary" header="Cabinet lié"
                header-bg-variant="primary" header-class="py-0 px-1 font-weight-bolder" header-text-variant="white"
        >
          <template #header>
            <h6 class="mb-0 font-weight-bolder text-white">Cabinet lié</h6>
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" class="btn-icon rounded-circle text-white"
                      variant="flat-success"
            >
              <feather-icon icon="Edit3Icon" size="16"/>
            </b-button>
          </template>
          <b-card-text>
            <vue-good-table
                :columns="columnsCabinetLie"
                :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 5,
                rowsPerPageLabel: '',
                dropdownAllowAll: false,
                perPageDropdownEnabled: false,
                nextLabel: 'Suivant',
                prevLabel: 'Précédent',
                ofLabel: 'de',
                infoFn: params => ``
              }"
                :rows="rowsCabinetLie"
                :rtl="direction"
                style-class="vgt-table condensed"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Action -->
                <span v-if="props.column.field === 'actions'">
                  <span>
                    <b-dropdown no-caret toggle-class="text-decoration-none" variant="link">
                      <template v-slot:button-content>
                        <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16"/>
                      </template>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="Edit2Icon"/>
                        <span>Éditer</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="PaperclipIcon"/>
                        <span>Joindre</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="TrashIcon"/>
                        <span>Supprimer</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
              </template>
              <div slot="emptystate" class="text-center align-middle">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
              </div>
            </vue-good-table>
          </b-card-text>
        </b-card>
        <b-card v-show="false" bg-variant="transparent" body-class="p-2" border-variant="primary"
                class="h-100" header="Activité" header-bg-variant="primary"
                header-class="py-0 px-1 font-weight-bolder" header-text-variant="white"
        >
          <template #header>
            <h6 class="mb-0 font-weight-bolder text-white">Votre activité</h6>
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" class="btn-icon rounded-circle text-white"
                      variant="flat-success"
            >
              <feather-icon icon="Edit3Icon" size="16"/>
            </b-button>
          </template>
          <b-card-text>
            <b-col class="mb-2" md="12">
              <b-row>
                <b-col md="3">
                  Chiffre d'affaires :
                </b-col>
                <b-col md="9">
                  {{ $_.get(currentUser.courtier_user[0].courtier.personne_morale, 'chiffreAffaire', null) }}
                </b-col>
              </b-row>
            </b-col>
            <vue-good-table
                :columns="columnsActivite"
                :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 5,
                rowsPerPageLabel: '',
                dropdownAllowAll: false,
                perPageDropdownEnabled: false,
                nextLabel: 'Suivant',
                prevLabel: 'Précédent',
                ofLabel: 'de',
                infoFn: params => ``
              }"
                :rows="rowsActivite"
                :rtl="direction"
                style-class="vgt-table condensed"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Action -->
                <span v-if="props.column.field === 'actions'">
                  <span>
                    <b-dropdown no-caret toggle-class="text-decoration-none" variant="link">
                      <template v-slot:button-content>
                        <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16"/>
                      </template>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="Edit2Icon"/>
                        <span>Éditer</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="PaperclipIcon"/>
                        <span>Joindre</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="TrashIcon"/>
                        <span>Supprimer</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
              </template>
              <div slot="emptystate" class="text-center align-middle">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
              </div>
            </vue-good-table>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
        id="modal-change-notion-id"
        ref="modal-change-notion"
        :cancel-disabled="busy"
        :ok-disabled="busy"
        :title="ComposerDataContact.value"
        cancel-title="Fermer"
        cancel-variant="outline-danger"
        centered
        no-close-on-backdrop
        modal-class="modal-primary"
        ok-title="Accepter"
        @ok.prevent="onSubmitChangeCollaborateurNotion"
    >
      <b-card-body>
        <validation-observer ref="changeNotionCollaboratorComposeRules">
          <form ref="form" @submit.stop.prevent="onSubmitChangeCollaborateurNotion">


            <validation-provider #default="{ errors }" name="collaborateur" rules="required">
              <b-form-group :state="errors.length > 0 ? false : null" label="Collaborateur *" label-for="collaborateur">
                <v-select v-model="newCourtierUserId"
                          :close-on-select="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :disabled="busy"
                          :options="collaborateurOptions"
                          :reduce="collab => collab.courtier_user_id"
                          class="flex-grow-1"
                          input-id="courtier_user_id" label="nom" placeholder="Collaborateur"
                >
                  <template #option="{ avatar, nom }">
                    <b-avatar :src="avatar" size="sm"/>
                    <span class="ml-50"> {{ nom }}</span>
                  </template>

                  <template #selected-option="{ avatar, nom }">
                    <b-avatar :src="avatar" class="border border-white" size="sm"/>
                    <span class="ml-50"> {{ nom }}</span>
                  </template>
                  <div slot="no-options">Aucune collaborateur disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </form>

          <b-overlay :show="busy" no-wrap @shown="onShown">
            <template v-slot:overlay>
              <div v-if="processing" class="text-center">
                <b-overlay
                    :show="busy"
                    rounded="sm"
                />
              </div>
              <div v-else ref="dialog" aria-labelledby="form-confirm-label" aria-modal="false" class="text-center p-3"
                   role="dialog" tabindex="-1"
              >
                <p><strong id="form-confirm-label">Êtes-vous sûr de vouloir désigner {{ newCourtierUserId ? collaborateurOptions.find(item => item.courtier_user_id === newCourtierUserId).nom : '' }} comme {{ ComposerDataContact.value }} du cabinet ?</strong></p>
                <div class="d-flex">
                  <b-button size="sm" v-ripple.400="'rgba(234, 84, 85, 0.15)'" class="mr-3" variant="outline-danger"
                            @click="onCancel"
                  > Annuler
                  </b-button>
                  <b-button size="sm" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-success"
                            @click="onOkSubmitChangeCollaborateurNotion"
                  > Oui
                  </b-button>
                </div>
              </div>
            </template>
          </b-overlay>

        </validation-observer>
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BIcon,
  BListGroup,
  BListGroupItem,
  BModal,
  BOverlay,
  BProgress,
  BRow,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import UpdateCabinetJuridique from './UpdateCabinetJuridique.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    // VBT
    BOverlay,
    BForm,
    BProgress,
    BIcon,
    BCardBody,
    BModal,
    BRow,
    BCol,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BButton,
    BDropdown,
    BDropdownItem,
    BCardHeader,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,

    // VGT
    VueGoodTable,

    //vue select
    vSelect,

    // UI
    UpdateCabinetJuridique,

    // validation
    ValidationObserver,
    ValidationProvider
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    currentUser: {
      type: Object,
      default: null,
      required: true
    },
    interlocuteurs: {
      type: Object,
      default: null,
      required: true,
    },
    correspondantTracfin: {
      type: Object,
      default: null,
      required: true,
    },
    declarantTracfin: {
      type: Object,
      default: null,
      required: true,
    },
    dpo: {
      type: Object,
      default: null,
      required: true,
    }
  },
  data() {
    return {
      busy: false,
      processing: false,
      required,
      columnsFinance: [
        {
          field: 'date_debut',
          label: 'Début',
          type: 'date',
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          field: 'date_fin',
          label: 'Fin',
          type: 'date',
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          field: 'ca',
          label: 'Chiffres d\'affaire',
          sortable: false,
          type: 'decimal',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          field: 'jusif',
          label: 'Justificatif',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          field: 'effectif',
          label: 'Effectif',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          field: 'actions',
          label: 'Actions',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      columnsActionnariat: [
        {
          label: 'Nom',
          field: 'nom_prenom',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: '% Capital',
          field: 'part',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      columnsCabinetLie: [
        {
          label: 'Nom',
          field: 'nom',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Lien',
          field: 'lien',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Début',
          field: 'date_debut',
          type: 'date',
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Fin',
          field: 'date_fin',
          type: 'date',
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Statut',
          field: 'statut',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      columnsActivite: [
        {
          label: 'Risque',
          field: 'risque',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: '% CA',
          field: 'ca',
          type: 'decimal',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Nombre AFN',
          field: 'nbafn_an',
          type: 'decimal',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Produit',
          field: 'prod',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      rowsFinance: [],
      rowsActionnariat: [],
      rowsCabinetLie: [],
      rowsActivite: [],
      collaborateurOptions: [],
      newCourtierUserId: null,
      ComposerDataContact: {
        value: null,
        key: null,
        courtierUserId: null
      }
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  created() {
    this.fetchDataCollaborateurByCourtier()
  },
  methods: {
    changeCollaboratorNotion(courtierUserId, key) {
      switch (key) {
        case 'isPrincipal':
          this.ComposerDataContact.value = 'Administrateur BubbleIn'
          this.ComposerDataContact.key = 'isPrincipal'
          this.ComposerDataContact.courtierUserId = courtierUserId
          break
        case 'correspondant_tracfin':
          this.ComposerDataContact.value = 'Correspondant Tracfin'
          this.ComposerDataContact.key = 'correspondant_tracfin'
          this.ComposerDataContact.courtierUserId = courtierUserId
          break
        case 'declarant_tracfin':
          this.ComposerDataContact.value = 'Déclarant Tracfin'
          this.ComposerDataContact.key = 'declarant_tracfin'
          this.ComposerDataContact.courtierUserId = courtierUserId
          break
        case 'dpo':
          this.ComposerDataContact.value = 'DPO'
          this.ComposerDataContact.key = 'dpo'
          this.ComposerDataContact.courtierUserId = courtierUserId
          break

      }
      this.$refs['modal-change-notion'].show()
    },
    onSubmitChangeCollaborateurNotion() {
      this.$refs.changeNotionCollaboratorComposeRules.validate()
          .then(success => {
            if (success) {
              this.processing = false
              this.busy = true
            }
          })
    },
    onOkSubmitChangeCollaborateurNotion() {
      this.processing = true
      this.$http
          .put(`/courtier/changeCollaboratorNotion/${this.newCourtierUserId}`, this.ComposerDataContact)
          .then(res => {
            if (res.data.success) {
              this.$emit('change-collaborateur-notion-returned')
              this.busy = this.processing = false
              this.ComposerDataContact = {
                value: null,
                key: null,
                courtierUserId: null
              }
              this.newCourtierUserId = null
              this.$refs['modal-change-notion'].hide()
              this.messageToast(res.data.message, 'Succès', 'success')
            }
          })
          .catch(err => {
            this.busy = this.processing = false
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          })
    },
    onCancel() {
      this.busy = false
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    fetchDataCollaborateurByCourtier() {
      this.collaborateurOptions = []
      this.$http
          .post('/prospect/fetchDataCollaborateurByCourtier', { courtier_id: this.currentUser.courtier_user[0].courtier_id })
          .then(res => {
            if (res.data.success) {
              this.collaborateurOptions = res.data.data
            }
          })
          .catch(err => {
            this.collaborateurOptions = []
            console.error(err)
          })
    },
    RecordCourtierJuridiqueReturned(data, message) {
      this.$emit('record-courtier-juridique-returned', data, message)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
